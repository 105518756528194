/* global Paddle */
import React, { useEffect, useState } from "react";
import "./App.css";
import { useAuth0 } from "@auth0/auth0-react";
import { auth, db } from "./Firebase"; // Firebase client SDK
import { doc, getDoc, setDoc, onSnapshot } from "firebase/firestore";
import { signInWithCustomToken } from "firebase/auth";
import LogoutButton from "./LogoutButton";
import { useNavigate } from "react-router-dom";
import TermsOfServiceModal from "./TermsOfServiceModal";

// Reusable function to exchange Auth0 token for Firebase custom token
export const authenticateWithFirebase = async (auth0Token) => {
  try {
    console.log("Authenticating with Firebase using custom token");

    const response = await fetch("/api/getFirebaseCustomToken", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ auth0Token }),
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch Firebase custom token: ${response.statusText}`);
    }

    const { firebaseCustomToken } = await response.json();
    await signInWithCustomToken(auth, firebaseCustomToken);
    console.log("Successfully signed in to Firebase");
  } catch (error) {
    console.error("Error signing in to Firebase:", error);
    throw error;
  }
};

const Pay = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  // Basic local state for business info & Paddle data
  const [businessInfo, setBusinessInfo] = useState({ businessName: "", website: "" });
  const [isPaddleLoaded, setIsPaddleLoaded] = useState(false);
  const [billingCycle, setBillingCycle] = useState("year");
  const [prices, setPrices] = useState({
    annually: "$0 per year",
    monthly: "$0 per month",
  });

  // Terms of Service states
  const [showTerms, setShowTerms] = useState(false);
  const [tosChecked, setTosChecked] = useState(false);

  // Environment variables for Paddle config
  const PADDLE_CLIENT_TOKEN = process.env.REACT_APP_PADDLE_CLIENT_TOKEN;
  const PRICE_ID_MONTHLY = process.env.REACT_APP_PADDLE_PRICE_ID_MONTHLY;
  const PRICE_ID_ANNUALLY = process.env.REACT_APP_PADDLE_PRICE_ID_ANNUALLY;

  /* -------------------------------------------------------
   * 1) On mount or when user changes, sign in & fetch business info
   * ----------------------------------------------------- */
  useEffect(() => {
    const fetchBusinessInfo = async () => {
      if (!user?.sub) return;

      try {
        // 1a) Exchange token and sign in once
        const auth0Token = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: "openid profile email",
        });
        await authenticateWithFirebase(auth0Token);

        // 1b) Read or create businessInfo doc
        const userDocRef = doc(db, "businessInfo", user.sub);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          setBusinessInfo(userDoc.data());
        } else {
          const defaultBusinessInfo = { businessName: "", website: "" };
          await setDoc(userDocRef, defaultBusinessInfo);
          setBusinessInfo(defaultBusinessInfo);
        }
      } catch (err) {
        console.error("Error fetching business info:", err);
      }
    };

    if (user) {
      fetchBusinessInfo();
    }
  }, [user, getAccessTokenSilently]);

  /* -------------------------------------------------------
   * 1c) Optional: real-time listener for subscription
   * ----------------------------------------------------- */
  useEffect(() => {
    if (!user?.sub) return;

    const userDocRef = doc(db, "users", user.sub);
    const unsubscribe = onSnapshot(userDocRef, (snapshot) => {
      const data = snapshot.data();
      if (data?.activeSubscription) {
        console.log("Subscription just turned active => navigating to Client Portal");
        unsubscribe(); // Stop listening, so we don't loop
        navigate("/client-portal");
      }
    });
    return () => unsubscribe();
  }, [user, navigate]);

  /* -------------------------------------------------------
   * 2) Load Paddle script once
   * ----------------------------------------------------- */
  useEffect(() => {
    if (window.Paddle) {
      setIsPaddleLoaded(true);
      fetchPrices();
      return;
    }

    const script = document.createElement("script");
    script.src = "https://cdn.paddle.com/paddle/v2/paddle.js";
    script.async = true;
    script.onload = () => {
      Paddle.Environment.set("sandbox"); // Change to "live" in production
      Paddle.Initialize({ token: PADDLE_CLIENT_TOKEN });
      setIsPaddleLoaded(true);
      fetchPrices();
    };
    script.onerror = () => console.error("Failed to load Paddle script.");
    document.body.appendChild(script);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // run once

  /* -------------------------------------------------------
   * 3) Fetch Paddle prices
   * ----------------------------------------------------- */
  const fetchPrices = async () => {
    if (!window.Paddle) {
      console.error("Paddle script not loaded yet.");
      return;
    }

    const fetchPrice = async (priceId) => {
      try {
        const response = await Paddle.PricePreview({ items: [{ quantity: 1, priceId }] });
        return response.data.details.lineItems[0].formattedTotals.subtotal.replace(".00", "");
      } catch (error) {
        console.error(`Error fetching price for ${priceId}:`, error);
        return "$0";
      }
    };

    try {
      const [annualPrice, monthlyPrice] = await Promise.all([
        fetchPrice(PRICE_ID_ANNUALLY),
        fetchPrice(PRICE_ID_MONTHLY),
      ]);
      setPrices({
        annually: `${annualPrice} per year`,
        monthly: `${monthlyPrice} per month`,
      });
    } catch (err) {
      console.error("Error fetching Paddle prices:", err);
    }
  };

  /* -------------------------------------------------------
   * 4) Checkout logic
   * ----------------------------------------------------- */
  const openCheckout = async () => {
    if (!businessInfo.businessName || !businessInfo.website) {
      alert("Please fill in both Business Name and Business Website before proceeding.");
      return;
    }

    // Ensure TOS is checked
    if (!tosChecked) {
      alert("Please accept the Terms & Conditions before proceeding.");
      return;
    }

    if (!isPaddleLoaded) {
      console.error("Paddle script not loaded yet.");
      return;
    }

    try {
      // 4a) Merge business info, TOS acceptance, AND SES fields into Firestore "users" doc
      const userDocRef = doc(db, "users", user?.sub);
      await setDoc(
        userDocRef,
        {
          ...businessInfo,
          termsAndServicesAccepted: true,
          emailAddress: user?.email || "",
          emailBounced: false,
          emailComplained: false,
          lastBounceTimestamp: null,
          lastComplaintTimestamp: null,
        },
        { merge: true }
      );

      const selectedPriceId = billingCycle === "month" ? PRICE_ID_MONTHLY : PRICE_ID_ANNUALLY;

      Paddle.Checkout.open({
        displayMode: "inline",
        frameTarget: "checkout-container",
        frameInitialHeight: "450",
        frameStyle: "width: 100%; min-width: 312px; backgroundColor: 'transparent'; border: 'none';",
        items: [{ priceId: selectedPriceId, quantity: 1 }],
        customer: { email: user?.email },
        customData: {
          userId: user?.sub,
          businessName: businessInfo.businessName,
          website: businessInfo.website,
        },

        // No automatic redirect or successCallback here
        errorCallback: (error) => {
          console.error("Error during checkout:", error);
          alert("An error occurred. Please try again.");
        },
      });
    } catch (error) {
      console.error("Error starting checkout:", error);
    }
  };

  /* -------------------------------------------------------
   * 5) Render UI
   * ----------------------------------------------------- */
  return (
    <div className="pay-container" style={{ fontFamily: "Arial, sans-serif", padding: "20px" }}>
      <LogoutButton />

      {/* Keep the note about limited spots at the top, if you still want the text, 
          otherwise remove or edit as needed. */}
      <div
        className="urgency-note"
        style={{
          background: "#fff4e5",
          border: "1px solid #f5c07b",
          padding: "0.75rem",
          borderRadius: "5px",
          marginBottom: "1rem",
          marginTop: "1rem",
          textAlign: "center",
          fontSize: "1rem",
          color: "#d35400",
        }}
      >
        {/* If you truly want no mention of limited spots, remove this div entirely. */}
        <strong>Limited subscriptions</strong> are available each month to ensure top-quality support.
        <br />
        Secure your spot today.
      </div>

      {/* Founder Testimonial */}
      <div
        className="testimonial"
        style={{
          fontStyle: "italic",
          background: "#f0f8ff",
          borderLeft: "4px solid #3498db",
          padding: "1rem",
          margin: "1.5rem 0",
          borderRadius: "4px",
          color: "#34495e",
        }}
      >
        <p>
          “I built the tool I wanted but couldn't when scaling my catering business—an
          intuitive intake form saving time and ensures accurate quotes.”
        </p>
        <cite
          style={{
            display: "block",
            textAlign: "right",
            fontStyle: "normal",
            fontWeight: "600",
            marginTop: "0.5rem",
          }}
        >
          — Joshua Young<br />
          <span style={{ fontSize: "0.9rem", fontWeight: "normal" }}>Founder & Developer</span>
        </cite>
      </div>

      {/* Features */}
      <h2>Features</h2>
      <div className="features-section" style={{ marginBottom: "20px" }}>
        <p>
          🔹 <strong>Effortless Website Embedding</strong>
          <br />
          <span style={{ fontSize: "0.9rem", display: "block" }}>
            Add our smart intake form to your site and match it to your brand’s look & feel.
          </span>
        </p>
        <p>
          🔹 <strong>Tailored to Your Business</strong>
          <br />
          <span style={{ fontSize: "0.9rem", display: "block" }}>
            Customize every detail—menus, presentation, beverages, and allergy-friendly options.
          </span>
        </p>
        <p>
          🔹 <strong>Support You Can Count On</strong>
          <br />
          <span style={{ fontSize: "0.9rem", display: "block" }}>
            We’re here to help whenever you need it.
          </span>
        </p>
        <p>
          🔹 <strong>Unlimited Quotes</strong>
          <br />
          <span style={{ fontSize: "0.9rem", display: "block" }}>
            Instantly receive all client intake form submissions directly to your inbox—no limits, ever.
          </span>
        </p>
      </div>


      {/* Business Details Form */}
      <form style={{ marginBottom: "20px", textAlign: "left" }}>
        <div style={{ marginBottom: "10px" }}>
          <label>
            Business Name:
            <input
              type="text"
              name="businessName"
              placeholder="Required"
              value={businessInfo.businessName}
              onChange={(e) => setBusinessInfo({ ...businessInfo, businessName: e.target.value })}
              required
              style={{
                display: "block",
                marginTop: "5px",
                padding: "5px",
                width: "100%",
                maxWidth: "300px",
              }}
            />
          </label>
        </div>
        <div>
          <label>
            Business Website:
            <input
              type="text"
              name="website"
              placeholder="Required"
              value={businessInfo.website}
              onChange={(e) => setBusinessInfo({ ...businessInfo, website: e.target.value })}
              style={{
                display: "block",
                marginTop: "5px",
                padding: "5px",
                width: "100%",
                maxWidth: "300px",
              }}
            />
          </label>
        </div>
      </form>

      {/* Pricing Toggle */}
      <div style={{ marginBottom: "20px", textAlign: "left" }}>
        <p>30-day Free-Trial</p>
        <div>
          <label style={{ display: "block", marginBottom: "10px" }}>
            <input
              type="radio"
              name="billingCycle"
              value="year"
              checked={billingCycle === "year"}
              onChange={() => setBillingCycle("year")}
            />
            {prices.annually}
          </label>
          <label style={{ display: "block" }}>
            <input
              type="radio"
              name="billingCycle"
              value="month"
              checked={billingCycle === "month"}
              onChange={() => setBillingCycle("month")}
            />
            {prices.monthly}
          </label>
        </div>
      </div>

      {/* Terms of Service Agreement Section */}
      <div style={{ margin: "20px 0", textAlign: "left" }}>
        <input
          type="checkbox"
          id="tosCheckbox"
          checked={tosChecked}
          onChange={(e) => setTosChecked(e.target.checked)}
        />
        <label htmlFor="tosCheckbox" style={{ marginLeft: "8px" }}>
          I agree to the{" "}
          <span
            className="terms-link"
            style={{ textDecoration: "underline", color: "blue", cursor: "pointer" }}
            onClick={(e) => {
              e.preventDefault(); // Prevents checkbox from toggling immediately
              setShowTerms(true);
            }}
          >
            Terms & Conditions
          </span>
        </label>
      </div>

      {showTerms && <TermsOfServiceModal onClose={() => setShowTerms(false)} />}

      {/* Paddle Checkout Inline Frame */}
      <div
        id="checkout-container"
        style={{
          marginBottom: "20px",
          border: "1px solid #ccc",
          borderRadius: "4px",
          padding: "10px",
        }}
      >
        {/* Paddle Checkout loads here */}
      </div>

      <button
        id="cta-button"
        onClick={openCheckout}
        className="cta-button"
        style={{
          display: "inline-block",
          background: "#e67e22",
          color: "#fff",
          textAlign: "center",
          fontSize: "1.1rem",
          padding: "0.75rem 1.5rem",
          border: "none",
          borderRadius: "4px",
          cursor: "pointer",
          fontWeight: "700",
        }}
      >
        Start My Subscription
      </button>
    </div>
  );
};

export default Pay;
