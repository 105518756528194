import React from "react";
import "./App.css";

const CancellationProcessModal = ({ onClose }) => {
  // Paddle customer portal URL (same as ClientPortal)
  const customerPortalUrl = "https://sandbox-customer-portal.paddle.com/cpl_01j8b0jkek534kde6wrcmv2gry";

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>
          &times;
        </button>

        <h2>Cancellation Process</h2>
        <p>
          If you cancel your subscription, you will continue to have access until the end of your current billing cycle.
        </p>

        <h3>Important Steps After Cancellation</h3>
        <p>
          To ensure the catering form is removed from your website, please delete the following embedded JavaScript code
          from your site:
        </p>

        <div className="code-block">
          <code>
            {`<div id="food-form-container"></div>
<script src="https://collaborative-catering-21ab4e9a8bfb.herokuapp.com/embed-script/YOUR_PUBLIC_ID"></script>`}
          </code>
        </div>

        <p>
          If you do not remove this code, the catering form will no longer display, but the script will remain on your
          site.
        </p>

        <h3>Manage Your Subscription</h3>
        <p>To cancel your subscription or manage billing details, visit your account portal:</p>
        <button
          className="manage-billing-button"
          onClick={() => {
            window.location.href = customerPortalUrl;
          }}
        >
          Manage Billing / Cancel Subscription
        </button>

        <h3>Need to Delete Your Account or Have Data Questions?</h3>
        <p>
          If you wish to permanently delete your Collaborative Catering account or have any questions regarding your data,
          please contact us at{" "}
          <a href="mailto:info@collaborativecatering.com">info@collaborativecatering.com</a>.
        </p>

        <button className="close-button-bottom" onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default CancellationProcessModal;
