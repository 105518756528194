import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FoodForm from './FoodForm';
import './App.css';
import LoginButton from './LoginButton';
import LogoutButton from './LogoutButton';
import { useAuth0 } from '@auth0/auth0-react';
import TermsOfServiceModal from './TermsOfServiceModal'; // Import the modal

const Home = () => {
  const [showForm, setShowForm] = useState(false);
  const [isFoodDeliverySelected, setIsFoodDeliverySelected] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const navigate = useNavigate();
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  const handleStartClick = () => {
    setShowForm(true);
  };

  const handleFoodDeliverySelection = (selectedOption) => {
    setIsFoodDeliverySelected(!!selectedOption);
  };

  return (
    <div>
      <header className="header">
        <div className="auth-buttons">
          <LoginButton />
          <LogoutButton />
        </div>
        <h1>Collaborative Catering</h1>
        <div className="homeHeader">
          <p>Smart Intake Form</p>
          <p>On Your Website</p>
        </div>
      </header>

      <section className="demo-section">
        {!showForm ? (
          <div className="demo-prompt">
            <button className="start-button" onClick={handleStartClick}>
              Demo
            </button>
          </div>
        ) : (
          <div className="food-form-section">
            <FoodForm
              // Example: pass your color config or theme
              primaryColor="#3498DB"
              secondaryColor="#AEDFF7"
              contrastColor="#ea9b47"
              backgroundColor="#FFFFFF"
              fontPrimary="Open Sans"
              
              // We want all three methods set to true:
              deliveryMethods={{
                delivered: true,
                droppedOff: true,
                pickedUp: true,
              }}
              
              isEmbedded={true}
              onFoodDeliveryChange={handleFoodDeliverySelection}
            />
          </div>
        )}
      </section>

      <footer className="site-footer">
        {isFoodDeliverySelected && (
          <div className="sign-up-footer">
            <button
              className="sign-up-button"
              onClick={() => loginWithRedirect({ screen_hint: 'signup' })}
            >
              Sign Up
            </button>
          </div>
        )}
        
        <p className="footer-links">
          <span 
            className="terms-link"
            style={{ textDecoration: 'underline', cursor: 'pointer', color: '#3498DB' }}
            onClick={() => setShowTermsModal(true)}
          >
            Terms of Service, privacy notice, and refund policy
          </span>
        </p>
      </footer>

      {showTermsModal && <TermsOfServiceModal onClose={() => setShowTermsModal(false)} />}
    </div>
  );
};

export default Home;
