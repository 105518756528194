import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import axios from 'axios';

// import { Range } from 'react-range'; no

import buffetIcon from './components/Images/Presentation Images/Buffet.png';
import dropOffIcon from './components/Images/Presentation Images/Drop Off At Venue.png';
import familyStyleIcon from './components/Images/Presentation Images/Family Style.png';
import madeToOrderIcon from './components/Images/Presentation Images/Made To Order.png';
import mixedIcon from './components/Images/Presentation Images/Mixed.png';
import otherIcon from './components/Images/Presentation Images/Other.png';
import pickUpIcon from './components/Images/Presentation Images/Pick Up From Kitchen.png';
import platedIcon from './components/Images/Presentation Images/Plated.png';
import './App.css';
import WebFont from 'webfontloader';

const loadFont = (fontName) => {
  WebFont.load({
    google: {
      families: [fontName],
    },
  });
};
function FoodForm({ 
  publicId, primaryColor, secondaryColor, contrastColor, backgroundColor, 
  fontPrimary, isEmbedded = false, onFoodDeliveryChange, deliveryMethods
})
 {
  useEffect(() => {
    console.log("FoodForm sees deliveryMethods:", deliveryMethods);
  }, [deliveryMethods]);

  // If deliveryMethods is missing or null, fallback to empty object (or handle gracefully)
  const safeDeliveryMethods = deliveryMethods || {};

  // Now filter out the ones that are true
  const availableMethods = Object.entries(safeDeliveryMethods)
    .filter(([_, isEnabled]) => isEnabled)
    .map(([methodName]) => methodName);
  useEffect(() => {
    if (fontPrimary) {
      const fontName = fontPrimary.replace(/['"]+/g, '');
      loadFont(fontName);
      document.documentElement.style.setProperty('--font-primary', fontPrimary);
    } else {
      document.documentElement.style.removeProperty('--font-primary');
    }

    if (primaryColor) {
      document.documentElement.style.setProperty('--primary-color', primaryColor);
    } else {
      document.documentElement.style.removeProperty('--primary-color');
    }

    if (secondaryColor) {
      document.documentElement.style.setProperty('--secondary-color', secondaryColor);
    } else {
      document.documentElement.style.removeProperty('--secondary-color');
    }

    if (contrastColor) {
      document.documentElement.style.setProperty('--contrast-color', contrastColor);
    } else {
      document.documentElement.style.removeProperty('--contrast-color');
    }

    if (backgroundColor) {
      document.documentElement.style.setProperty('--background-color', backgroundColor);
    } else {
      document.documentElement.style.removeProperty('--background-color');
    }
  }, [primaryColor, secondaryColor, contrastColor, backgroundColor, fontPrimary]);

  const [errorMessages, setErrorMessages] = useState({});
  const validateForm = () => {
    const errors = {};

    if (!firstName.trim()) {
      errors.firstName = 'First Name is required.';
    }
    if (!lastName.trim()) {
      errors.lastName = 'Last Name is required.';
    }
    if (!phoneNumber.trim()) {
      errors.phoneNumber = 'Phone Number is required.';
    } else if (!/^\d{10}$/.test(phoneNumber)) {
      errors.phoneNumber = 'Phone Number must be 10 digits.';
    }
    if (!customerEmailAddress.trim()) {
      errors.customerEmailAddress = 'Email Address is required.';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(customerEmailAddress)) {
      errors.customerEmailAddress = 'Email Address is invalid.';
    }

    setErrorMessages(errors);

    // If no errors, return true; otherwise, return false
    return Object.keys(errors).length === 0;
  };


  const handleInputChange = (setter) => (event) => {
    setter(event.target.value);
  };
  const [editableDocument, setEditableDocument] = useState('');
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    if (!validateForm()) {
      // Form is invalid, do not proceed with submission
      return;
    }

    // Prepare the formatted data as before
    const formattedData = {
      'Guest Count': {},
      'Presentation': `${foodDeliveryOption} - ${presentation}`,
      'Equipment': getEquipmentProvided(),
      'Food Selection': getFoodSelection(),
      'Food Selection Notes': textareaValue,
      'Food Accommodations': [],
      'Drink Selections': [],
      'Event Type': event,
      'Event Details': {},
      'Food Service': {},
      'Venue Details': populateVenueDetails(),
      'Time Line': populateTimeLine(),
      'Budget': {},
      'Contact Information': {
        'First Name': firstName,
        'Last Name': lastName,
        'Phone Number': phoneNumber,
        'Email Address': customerEmailAddress,
        'Additional Notes': additionalNotes,
      },
    };

    function getEquipmentProvided() {
      const equipmentProvided = {
        Disposable: [],
        Reusable: [],
      };
      equipmentLabels.forEach(label => {
        if (providedBy[label] === 'Disposable') {
          equipmentProvided.Disposable.push(label);
        } else if (providedBy[label] === 'Reusable') {
          equipmentProvided.Reusable.push(label);
        }
      });
      return equipmentProvided;
    }

    function getFoodSelection() {
      const foodSelection = {};
      if (foodSelections.appetizers) {
        foodSelection['Appetizers'] = formatFoodSelection(
          foodSelections.appetizers,
          appetizersStyle,
          appetizersOther,
          appetizersBuffetSubStyle
        );
      }
      if (foodSelections.bread) {
        foodSelection['Breads'] = formatFoodSelection(
          foodSelections.bread,
          breadStyle,
          breadOther,
          breadBuffetSubStyle
        );
      }
      if (foodSelections.salads) {
        foodSelection['Salads'] = formatFoodSelection(
          foodSelections.salads,
          saladsStyle,
          saladsOther,
          saladsBuffetSubStyle
        );
      }
      if (foodSelections.sides) {
        foodSelection['Sides'] = formatFoodSelection(
          foodSelections.sides,
          sidesStyle,
          sidesOther,
          sidesBuffetSubStyle
        );
      }
      if (foodSelections.entree) {
        foodSelection['Entrées'] = formatFoodSelection(
          foodSelections.entree,
          entreeStyle,
          entreeOther,
          entreeBuffetSubStyle
        );
      }
      if (foodSelections.dessert) {
        foodSelection['Desserts'] = formatFoodSelection(
          foodSelections.dessert,
          dessertStyle,
          dessertOther,
          dessertBuffetSubStyle
        );
      }
      return foodSelection;
    }

    function formatFoodSelection(value, style, other, buffetSubStyle) {
      let formattedValue = value;
      if (style && style !== 'Buffet') {
        formattedValue += `, Style: ${style}`;
      }
      if (style === 'Buffet' && buffetSubStyle) {
        formattedValue += `, Buffet Style: ${buffetSubStyle}`;
      }
      if (style === 'Other' && other) {
        formattedValue += `, Other Details: ${other}`;
      }
      return formattedValue;
    }

    function handleProvidedByChange(label, value) {
      setProvidedBy(prevState => {
        if (prevState[label] === value) {
          return { ...prevState, [label]: '' };
        } else {
          return { ...prevState, [label]: value };
        }
      });
    }

    // function getSelectedServices() {
    //   const selectedServices = [];
    //   serviceLabels.forEach(label => {
    //     if (providedBy[label] === 'Requested') {
    //       selectedServices.push(label);
    //     }
    //   });
    //   return selectedServices;
    // }

    function formatDataToString(data) {
      let formattedString = '';
      for (const key in data) {
        formattedString += `${key}:\n`;
        if (Array.isArray(data[key]) && data[key].length) {
          data[key].forEach(item => {
            formattedString += `  ${item}\n`;
          });
        } else if (typeof data[key] === 'object' && Object.keys(data[key]).length) {
          const subData = data[key];
          for (const subKey in subData) {
            if (Array.isArray(subData[subKey])) {
              formattedString += `  ${subKey}: ${subData[subKey].join(', ')}\n`;
            } else if (subData[subKey]) {
              formattedString += `  ${subKey}: ${subData[subKey]}\n`;
            }
          }
        } else if (typeof data[key] === 'string' && data[key].trim()) {
          formattedString += `  ${data[key]}\n`;
        }
      }
      return formattedString.trim();
    }

    if (event === 'One time event') {
      formattedData['Event Details'] = date || ''; // Use date or an empty string
    } else if (event === 'Reoccurring') {
      const firstLastEvent = `First Event ${firstEvent || ''} Last Event ${lastEvent || ''}`.trim();
      const frequency = recurringFrequency === 'Frequency' ? customFrequency || '' : recurringFrequency || '';
      formattedData['Event Details'] = `${firstLastEvent}, ${frequency}`.trim();
    }
    function populateVenueDetails() {
      const venueDetailsEntries = [];

      if (streetAddress) {
        venueDetailsEntries.push(`Street Address: ${streetAddress}`);
      }
      if (zipCode) {
        venueDetailsEntries.push(`Zip Code: ${zipCode}`);
      }
      if (city) {
        venueDetailsEntries.push(`City: ${city}`);
      }
      if (selectedState) {
        venueDetailsEntries.push(`State: ${selectedState}`);
      }
      const kitchenOptionsFiltered = kitchenOptions.map(option => option.type).filter(type => type !== "placeholder");
      if (kitchenOptionsFiltered.length > 0) {
        venueDetailsEntries.push(`Kitchen Options: ${kitchenOptionsFiltered.join(', ')}`);
      }
      if (potableWater) {
        venueDetailsEntries.push(`Potable Water: ${potableWater}`);
      }

      return venueDetailsEntries.join(', ');
    }

    function populateTimeLine() {
      const timelineEntries = [];

      // Handle Pick Up Option
      if (foodDeliveryOption === 'Pick Up' && pickUpTime) {
        timelineEntries.push(`Pick Up Time: ${pickUpTime}`);
      } else if (foodDeliveryOption === 'Pick Up' && previousTimeLine['Pick Up Time']) {
        timelineEntries.push(`Pick Up Time: ${previousTimeLine['Pick Up Time']}`);
      }

      // Handle DropOff Option
      if (foodDeliveryOption === 'DropOff' && dropOffTime) {
        timelineEntries.push(`Drop Off Time: ${dropOffTime}`);
      } else if (foodDeliveryOption === 'DropOff' && previousTimeLine['Drop Off Time']) {
        timelineEntries.push(`Drop Off Time: ${previousTimeLine['Drop Off Time']}`);
      }

      // Include Venue Access and Food Service times if Delivered
      if (foodDeliveryOption === 'Delivered') {
        if (venueAccessTime) {
          timelineEntries.push(`Venue Access Time: ${venueAccessTime}`);
        } else if (previousTimeLine['Venue Access Time']) {
          timelineEntries.push(`Venue Access Time: ${previousTimeLine['Venue Access Time']}`);
        }

        if (startTime) {
          timelineEntries.push(`Food Service Start Time: ${startTime}`);
        } else if (previousTimeLine['Food Service Start Time']) {
          timelineEntries.push(`Food Service Start Time: ${previousTimeLine['Food Service Start Time']}`);
        }

        if (endTime) {
          timelineEntries.push(`Food Service End Time: ${endTime}`);
        } else if (previousTimeLine['Food Service End Time']) {
          timelineEntries.push(`Food Service End Time: ${previousTimeLine['Food Service End Time']}`);
        }
      }

      // Always include notes, regardless of foodDeliveryOption
      if (foodTimeLineNotes) {
        timelineEntries.push(`Notes: ${foodTimeLineNotes}`);
      } else if (previousTimeLine['Notes']) {
        timelineEntries.push(`Notes: ${previousTimeLine['Notes']}`);
      }

      return timelineEntries.join(', ');
    }






    selectedAlcoholicDrinkTypes.forEach((drink, drinkIndex) => {
      let drinkDetails = `${drink.type}`;

      // Gather already selected subcategories to filter options
      const currentSelections = drink.subcategories.map(subcategory => subcategory.type).filter(type => type !== "placeholder");

      // Process subcategories
      drink.subcategories?.forEach((subcategory, subcategoryIndex) => {
        const subcategoryOptions = allSubcategoryOptions[drink.type] || [];
        const availableOptions = subcategoryOptions.filter(opt => !currentSelections.includes(opt) || opt === subcategory.type);

        // Determine if placeholder should be replaced
        let subcategoryType = subcategory.type;

        if (subcategoryType === "placeholder" && availableOptions.length === 1) {
          subcategoryType = availableOptions[0];
        }

        if (subcategoryType && subcategoryType !== "placeholder") {
          drinkDetails += `, ${subcategoryType}`;
        }

        // Replace 'placeholder' with 'Amount Undefined' if no amount is specified
        const allocationDetails = subcategory.everyoneFew === "placeholder" ? "Amount Undefined" : subcategory.everyoneFew;
        drinkDetails += `, ${allocationDetails}`;

        // Include specific number if applicable
        if (allocationDetails === "Specific Number") {
          const specificNumberKey = `${drink.type}_${drinkIndex}_${subcategoryIndex}`;
          const specificNumber = specificNumbers[specificNumberKey] || "Undefined Number";
          const personLabel = Number(specificNumber) === 1 ? 'Person' : 'People';
          drinkDetails += ` ${specificNumber} ${personLabel}`;
        }
      });

      // Process specific drinks
      drink.specificDrinks?.forEach((specificDrink, specificIndex) => {
        const specificDrinkDetails = specificDrink.details || "Undefined Drink Details";

        if (specificDrinkDetails) {
          drinkDetails += `, Specific Drink ${specificIndex + 1}: ${specificDrinkDetails}`;
          const dropdownValueKey = `${drink.type}_${drinkIndex}_${specificIndex}`;
          const dropdownValue = specificDrink.everyoneFew || "Undefined Amount";

          if (dropdownValue && dropdownValue !== "placeholder") {
            drinkDetails += `, Selection: ${dropdownValue}`;
            if (dropdownValue === "Specific Number" && specificDrink.specificNumber) {
              const specificNumberValue = specificDrink.specificNumber;
              const personLabel = Number(specificNumberValue) === 1 ? 'Person' : 'People';
              drinkDetails += ` ${specificNumberValue} ${personLabel}`;
            }
          }
        }
      });

      // Check before pushing to avoid unwanted phrase
      if (drinkDetails.includes("Specific Drink, Amount Undefined")) {
        drinkDetails = drinkDetails.replace("Specific Drink, Amount Undefined", ""); // Modify as needed
      }

      if (drink.type) {
        formattedData['Drink Selections'].push(drinkDetails);
      }
    });







    const foodCategories = [
      { label: 'Appetizers', value: appetizers, style: appetizersStyle, other: appetizersOther, buffetSubStyle: appetizersBuffetSubStyle },
      { label: 'Breads', value: bread, style: breadStyle, other: breadOther, buffetSubStyle: breadBuffetSubStyle },
      { label: 'Salads', value: salads, style: saladsStyle, other: saladsOther, buffetSubStyle: saladsBuffetSubStyle },
      { label: 'Sides', value: sides, style: sidesStyle, other: sidesOther, buffetSubStyle: sidesBuffetSubStyle },
      { label: 'Entrées', value: entree, style: entreeStyle, other: entreeOther, buffetSubStyle: entreeBuffetSubStyle },
      { label: 'Desserts', value: dessert, style: dessertStyle, other: dessertOther, buffetSubStyle: dessertBuffetSubStyle },
    ];

    foodAccommodations.forEach((accommodation, index) => {
      let accommodationDetails = `Accommodation ${index + 1}: `;
      if (accommodation.everyFew === 'placeholder') {
        accommodationDetails += "Amount Undefined";
      } else {
        if (accommodation.everyFew) {
          accommodationDetails += `${accommodation.everyFew}`;
          if (accommodation.everyFew === 'Specific Number' && accommodation.specificNumber) {
            accommodationDetails += ` (${accommodation.specificNumber} ${accommodation.specificNumber === "1" ? 'Person' : 'People'})`;
          }
        }
      }
      accommodationTypes.forEach(({ name, key }) => {
        if (accommodation[key]) {
          accommodationDetails += `, ${name}`;
        }
      });
      if (accommodation.other) {
        accommodationDetails += `, Other: ${accommodation.other}`;
      }

      const ignoreCondition = `Accommodation ${index + 1}: Amount Undefined`;
      if (accommodationDetails.trim() !== ignoreCondition) {
        formattedData['Food Accommodations'].push(accommodationDetails.trim());
      }
    });

    foodCategories.forEach(category => {
      if (category.value) {
        let details = `${category.value}`;
        if (category.style && category.style !== 'Buffet') {
          details += `, Style: ${category.style}`;
        }
        if (category.style === 'Buffet' && category.buffetSubStyle) {
          details += `, Buffet Style: ${category.buffetSubStyle}`;
        }
        if (category.style === 'Other' && category.other) {
          details += `, Other Details: ${category.other}`;
        }
        formattedData['Food Selection'][category.label] = details;
      }
    });

    if (presentation === 'Plated' && courses) {
      formattedData['Presentation'] += `, Courses: ${courses}`;
    }
    if (presentation === 'Family Style' && ppTable) {
      formattedData['Presentation'] += `, People Per Table: ${ppTable}`;
    }
    if (presentation === 'Buffet' && buffetSubStyle) {
      formattedData['Presentation'] += `, ${buffetSubStyle}`;
    }
    if (presentation === 'Other' && otherPresentation) {
      formattedData['Presentation'] += `, Other Details: ${otherPresentation}`;
    }
    const formatBudget = (minCount, maxCount) => {
      return minCount || maxCount ? `Min: ${minCount} Max: ${maxCount}` : '';
    };
    let budget = formatBudget(minBudget, maxBudget);
    if (budget) {
      formattedData['Budget'] = budget;
    }
    const formatGuestCount = (minCount, maxCount) => {
      return minCount || maxCount ? `Min: ${minCount} Max: ${maxCount}` : '';
    };
    let adultGuestCount = formatGuestCount(minAdultGuest, maxAdultGuest);
    if (adultGuestCount) {
      formattedData['Guest Count']['Adults'] = adultGuestCount;
    }
    let childGuestCount = formatGuestCount(minChildGuest, maxChildGuest);
    if (childGuestCount) {
      formattedData['Guest Count']['Children'] = childGuestCount;
    }




    try {
      // Send a POST request to your server's endpoint
      await axios.post('https://collaborative-catering-21ab4e9a8bfb.herokuapp.com/api/submitForm', {
        publicId, // Include the publicId to identify the user
        formData: formattedData, // Send the formatted data
      });
      alert('Form submitted successfully.');
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('An error occurred while submitting the form.');
    }

    const documentContent = formatDataToString(formattedData);
    setEditableDocument(documentContent);
  };

  const [previousTimeLine, setPreviousTimeLine] = useState({});



  const [foodDeliveryOption, setFoodDeliveryOption] = useState('');
  const handleOptionChange = (value) => {
    setFoodDeliveryOption(value);
    if (onFoodDeliveryChange) {
      onFoodDeliveryChange(value); // Call the callback with the selected value
    }
  };
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const handleStateChange = (e) => {
    setSelectedState(e.target.value);
  };
  const [venueAccessHours, setVenueAccessHours] = useState('');
  const [venueAccessMinutes, setVenueAccessMinutes] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [customerEmailAddress, setCustomerEmailAddress] = useState('');
  const MinGuests = 1;
  const [minGuest, setMinGuest] = React.useState(MinGuests);
  const [maxGuest, setMaxGuest] = React.useState(MinGuests);
  const [presentation, setPresentation] = useState('');
  const [courses, setCourses] = useState();
  const [ppTable, setppTable] = useState();
  const increaseValue = (increment = 1) => {
    setppTable(prev => prev + increment);
  };
  const decreaseValue = (decrement = 1) => {
    if (ppTable > decrement) {
      setppTable(prev => prev - decrement);
    }
  };
  const timeoutRef = useRef(null);
  const intervalRef = useRef(null);
  const [buffetStyle, setBuffetStyle] = useState('');
  const [buffetSubStyle, setBuffetSubStyle] = useState(''); const handleBuffetSubStyleChange = (selectedStyle) => {

    setBuffetSubStyle(prevStyle => prevStyle === selectedStyle ? '' : selectedStyle);
  };
  const [event, setEvent] = useState('');
  const accommodationTypes = [
    { name: "Vegetarian", key: "vegetarian" },
    { name: "Vegan", key: "vegan" },
    { name: "Gluten-Free", key: "glutenFree" },
    { name: "Kosher", key: "kosher" },
    { name: "Halal", key: "halal" },
    { name: "Lactose-Free", key: "lactoseFree" },
    { name: "Egg Allergy", key: "eggAllergy" },
    { name: "Other", key: "otherAllergy" }

  ];
  accommodationTypes.sort((a, b) => a.name.localeCompare(b.name)); const initialAccommodationState = {
    everyFew: 'placeholder',
    vegetarian: false,
    vegan: false,
    glutenFree: false,
    kosher: false,
    halal: false,
    lactoseFree: false,
    eggAllergy: false,
    fishAllergy: false,
    otherAllergy: false,
    specificNumber: '',
    other: '',
  };
  const [foodAccommodations, setFoodAccommodations] = useState([initialAccommodationState]);
  const handleChangeAccommodation = (index, field, value) => {
    const updatedAccommodations = [...foodAccommodations];

    if (field === 'specificNumber' || field === 'other') {
      updatedAccommodations[index][field] = value;

      if (field === 'other') {
        const textarea = document.querySelectorAll('.otherInputBoxDrinkFoodService')[index];
        textarea.style.height = 'auto'; // Reset the height
        textarea.style.height = `${textarea.scrollHeight}px`; // Set it to the scrollHeight
      }
    } else if (field === 'everyFew') {
      updatedAccommodations[index][field] = value;
    } else {
      updatedAccommodations[index][field] = !updatedAccommodations[index][field];
    }

    setFoodAccommodations(updatedAccommodations);
  };

  const handleAddAccommodation = () => {
    setFoodAccommodations([...foodAccommodations, initialAccommodationState]);
  };
  const handleRemoveAccommodation = (index) => {
    const updatedAccommodations = [...foodAccommodations];
    updatedAccommodations.splice(index, 1);
    setFoodAccommodations(updatedAccommodations);
  };
  const isAccommodationSelected = (accommodation) => {
    // Check if any checkbox value is true
    const checkboxSelected = Object.values(accommodation).some(value => typeof value === "boolean" && value);

    // Check if 'other' field is non-empty and not just white space
    // Safely access 'other' with accommodation.other?.trim() to prevent any undefined issues
    const otherFieldNonEmpty = accommodation.other?.trim() !== "";

    // Return true if either condition is met
    return checkboxSelected || otherFieldNonEmpty;
  };

  const renderFoodAccommodationsCard = () => {
    return (
      <div className="">
        <h3>Food Accommodations</h3>
        <div className='accommodations-container'>

          {foodAccommodations.map((accommodation, index) => (
            <div className="drinkCardAccomodations" key={index}>
              {index !== 0 && (
                <button className="removeOptionAccomodation" onClick={() => handleRemoveAccommodation(index)}>X</button>
              )}
              <div className='eveFewAccomodation'>
                <select
                  className="customDropdownAccomodations"
                  value={accommodation.everyFew} onChange={e => handleChangeAccommodation(index, 'everyFew', e.target.value)}>
                  <option value="placeholder" disabled={index !== 0}>How Many?</option> {/* Only disable the placeholder for index other than 0 */}
                  <option value="100% of Guests">100% of Guests</option>
                  <option value="75% of Guests">75% of Guests</option>
                  <option value="50% of Guests">50% of Guests</option>
                  <option value="25% of Guests">25% of Guests</option>
                  <option value="Specific Number">Specifc #</option>
                </select>
                {accommodation.everyFew === 'Specific Number' && (
                  <div>
                    <input
                      type="number"
                      min="1"
                      placeholder='0'
                      value={accommodation.specificNumber}
                      onChange={(e) => handleChangeAccommodation(index, 'specificNumber', e.target.value)}
                      className="custom-inputAccomodations"
                    />
                    <label className='accomodationLabel'>
                      {accommodation.specificNumber === "1" ? 'Person' : 'People'}
                    </label>
                  </div>
                )}
              </div>
              <div className="accommodations-container-food">
                {accommodationTypes.filter(type => type.key !== "otherAllergy").map(({ name, key }) => (
                  <label className="accommodation-checkbox" key={key}>
                    <input
                      type="checkbox"
                      checked={accommodation[key]}
                      onChange={() => handleChangeAccommodation(index, key)}
                    />
                    {name}
                  </label>
                ))}
                <textarea
                  placeholder="Other"
                  value={accommodation.other}
                  onChange={(e) => {
                    handleChangeAccommodation(index, 'other', e.target.value);
                  }}
                  className='otherInputBoxDrinkFoodService'
                  style={{ overflow: 'hidden' }}
                  rows={1}
                />

              </div>
              {index === foodAccommodations.length - 1 && isAccommodationSelected(accommodation) && (
                <button onClick={handleAddAccommodation}>+ More</button>
              )}
            </div>))}
        </div>
      </div>
    );
  };
  const [foodAccommodation, setFoodAccommodation] = useState('N');
  const [addAccommodation, setAddAccommodation] = useState('No');
  const [people, setPeople] = useState("Catering for how many?");
  const [recurringFrequency, setRecurringFrequency] = useState('');
  const [customFrequency, setCustomFrequency] = useState('');
  const [otherPresentation, setOtherPresentation] = useState('');
  const [foodTimeLineNotes, setfoodTimeLineNotes] = useState('');
  const [appetizersBuffetSubStyle, setAppetizersBuffetSubStyle] = useState('');
  const [breadBuffetSubStyle, setBreadBuffetSubStyle] = useState('');
  const [sidesBuffetSubStyle, setSidesBuffetSubStyle] = useState('');
  const [saladsBuffetSubStyle, setSaladsBuffetSubStyle] = useState('');
  const [entreeBuffetSubStyle, setEntreeBuffetSubStyle] = useState('');
  const [dessertBuffetSubStyle, setDessertBuffetSubStyle] = useState('');
  const handleStart = () => {
    const startButton = document.getElementById('start-button');
    const initialForm = document.getElementById('initial-form');
    startButton.style.display = 'none';
    initialForm.style.display = 'none';
    const sections = document.getElementsByClassName('section');
    for (let i = 0; i < sections.length; i++) {
      sections[i].style.display = 'block';
    }
  };
  const handleChange = (setter) => (event) => {
    setter(event.target.value);
  };
  const handleChangeFood = (event) => {
    const textarea = event.target;
    setTextareaValue(textarea.value);
    adjustTextareaRows(textarea);
  };
  const adjustTextareaHeight = (textarea) => {
    textarea.style.height = 'auto';
    textarea.style.height = textarea.scrollHeight + 'px';
  };
  const adjustTextareaHeightTimeLine = (textarea) => {
    textarea.style.height = `${textarea.scrollHeight}px`;
  }; function PresentationIcon({ src, alt, label, value, selected, onClick, extraSpace }) {
    const isSelected = selected === value;
    return (
      <div
        className={`icon-container ${isSelected ? 'selected' : (selected ? 'dimmed' : '')} ${extraSpace ? 'extra-space' : ''}`}
        onClick={() => onClick(value)}
      >
        <img className="presentation-icon" src={src} alt={alt} />
        {/* Use dangerouslySetInnerHTML to render HTML in label */}
        <p className="icon-label" dangerouslySetInnerHTML={{ __html: label }}></p>
        {extraSpace ? <div className="spacer"></div> : null}
      </div>
    );
  }
  const presentationOptions = {
    'Drop Off': 'Caterer will drop food off at venue.',
    'Pick Up': 'Pick up food at the caters address',
    'Orders Taken': 'Guests will specify what food they want.',
    'Plated': 'Food served on a plate.',
    'Buffet': 'Guests line up at the food station.',
    'Family Style': 'Food served on platters passed by guests.',
    'Mixed': 'Select presentation for each food and beverage.'
  };
  const [mixedPresentations, setMixedPresentations] = useState([]);































  const [alcoholic, setAlcoholic] = useState('No');
  const [alcoholicPeople, setAlcoholicPeople] = useState('');
  const [nonAlcoholic, setNonAlcoholic] = useState('No');
  const [nonAlcoholicPeople, setNonAlcoholicPeople] = useState('');
  const [foodSelections, setFoodSelections] = useState({
    appetizers: '',
    bread: '',
    sides: '',
    salads: '',
    entree: '',
    dessert: '',
  });

  const handleFoodSelectionUpdate = (foodType, value) => {
    setFoodSelections(prev => ({
      ...prev,
      [foodType]: value,
    }));
  };
  const [appetizers, setAppetizers] = useState();
  const [bread, setBread] = useState();
  const [sides, setSides] = useState();
  const [salads, setSalads] = useState();
  const [entree, setEntree] = useState();
  const [dessert, setDessert] = useState();
  const [appetizersStyle, setAppetizersStyle] = useState('');
  const [breadStyle, setBreadStyle] = useState('');
  const [sidesStyle, setSidesStyle] = useState('');
  const [saladsStyle, setSaladsStyle] = useState('');
  const [entreeStyle, setEntreeStyle] = useState('');
  const [dessertStyle, setDessertStyle] = useState('');
  const [appetizersOther, setAppetizersOther] = useState('');
  const [breadOther, setBreadOther] = useState('');
  const [sidesOther, setSidesOther] = useState('');
  const [saladsOther, setSaladsOther] = useState('');
  const [entreeOther, setEntreeOther] = useState('');
  const [dessertOther, setDessertOther] = useState('');
  const [startTime, setStartTime] = useState('');
  const [venueAccessTime, setVenueAccessTime] = useState('');
  const [pickUpTime, setPickUpTime] = useState('');
  const [dropOffTime, setDropOffTime] = useState('');

  const [endTime, setEndTime] = useState('');
  useEffect(() => {
    setEndTime(startTime);
  }, [startTime]);
  const [date, setDate] = useState('');
  const [recurringOption, setRecurringOption] = useState('Daily');
  const [firstEvent, setFirstEvent] = useState('');
  const [lastEvent, setLastEvent] = useState('');
  useEffect(() => {
    if (new Date(firstEvent) > new Date(lastEvent)) {
      setLastEvent(firstEvent);
    }
  }, [firstEvent, lastEvent]);
  useEffect(() => {
    if (new Date(endTime) < new Date(startTime)) {
      setEndTime(startTime);
    }
  }, [startTime, endTime]);
  const [started, setStarted] = useState(false);
  const handleAlcoholicChange = (event) => {
    setAlcoholic(event.target.checked ? 'Yes' : 'No');
  };
  const handleNonAlcoholicChange = (event) => {
    setNonAlcoholic(event.target.checked ? 'Yes' : 'No');
  };
  const handleNonAlcoholicPeopleChange = (e) => {
    if (parseInt(e.target.value, 10) <= parseInt(maxGuest, 10)) {
      setNonAlcoholicPeople(e.target.value);
    }
  };
  const handleAppetizersChange = (event) => {
    setAppetizers(event.target.value);
  };

  const handleBreadChange = (event) => {
    setBread(event.target.value);
  };
  const handleSidesChange = (event) => {
    setSides(event.target.value);
  };
  const handleSaladsChange = (event) => {
    setSalads(event.target.value);
  };
  const handleEntreeChange = (event) => {
    setEntree(event.target.value);
  };
  const handleDessertChange = (event) => {
    setDessert(event.target.value);
  };
  const handleStartTimeChange = (event) => {
    setStartTime(event.target.value);
  };
  const handleVenueAccessChange = (event) => {
    setStartTime(event.target.value);
  };
  const handleEndTimeChange = (event) => {
    setEndTime(event.target.value);
  };
  const handleDateChange = (event) => {
    setDate(event.target.value);
  };
  const handleRecurringOptionChange = (event) => {
    setRecurringOption(event.target.value);
  };
  const handleFirstEventChange = (event) => {
    setFirstEvent(event.target.value);
  };
  const handleLastEventChange = (event) => {
    setLastEvent(event.target.value);
  };

  const renderBuffetOptions = (buffetSubStyle, setBuffetSubStyle, categoryLabel) => (
    <div className='presentation-boxBuffetSubCatergory presentation-margin'>
      <input
        type="checkbox"
        id={`${categoryLabel}-staffServed`}
        name={`${categoryLabel}-staffServed`}
        checked={buffetSubStyle === 'Staff Served'}
        onChange={() => setBuffetSubStyle(buffetSubStyle === 'Staff Served' ? '' : 'Staff Served')}
      />
      <label htmlFor={`${categoryLabel}-staffServed`}>Staff Served&nbsp;&nbsp;</label>
      <input
        type="checkbox"
        id={`${categoryLabel}-selfServe`}
        name={`${categoryLabel}-selfServe`}
        checked={buffetSubStyle === 'Self Served'}
        onChange={() => setBuffetSubStyle(buffetSubStyle === 'Self Served' ? '' : 'Self Served')}
      />
      <label htmlFor={`${categoryLabel}-selfServe`}>Self Served</label>
    </div>
  );
  const OtherInput = ({ otherValue, setOtherValue, disabled, className }) => {
    const [localValue, setLocalValue] = useState(otherValue);
    const textareaRef = useRef(null);

    useEffect(() => {
      setLocalValue(otherValue);
    }, [otherValue]);

    useEffect(() => {
      if (textareaRef.current) {
        textareaRef.current.style.height = 'inherit';
        const scrollHeight = textareaRef.current.scrollHeight;
        textareaRef.current.style.height = scrollHeight + "px";
      }
    }, [localValue]);
    const handleBlur = () => {
      if (localValue.trim() === '') {
        setOtherValue('');
      } else {
        setOtherValue(localValue);
      }
    };
    return (
      <textarea
        rows='1'
        ref={textareaRef}
        placeholder="Add Details"
        value={localValue}
        onChange={(e) => setLocalValue(e.target.value)}
        onBlur={handleBlur}
        disabled={disabled}
        className={className}
        style={{ overflow: "hidden", resize: "none" }}
      />
    );
  };
  const customStyles = {
    container: (provided) => ({
      ...provided,
      transform: 'scale(0.75)',
      transformOrigin: 'right',
      width: '175px',

    }),
    control: (provided) => ({
      ...provided,
      border: '1px solid #ccc',
      boxShadow: 'none',
      fontSize: '1rem',
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: 0,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'black'
    }),
    menuPortal: base => ({
      ...base,
      zIndex: 9999
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isDisabled ? 'gray' : state.isSelected ? 'black' : 'black',
      backgroundColor: state.isFocused ? '#f5f5f5' : null
    })
  };
  const FoodSelection = ({ label, name, initialvalue, onUpdate, onChange, value, styleValue, setStyleValue, otherValue, setOtherValue, buffetSubStyle, setBuffetSubStyle }) => {
    const [localValue, setLocalValue] = useState(initialvalue);
    const inputRef = useRef(null);
    const handleChange = (e) => {
      setLocalValue(e.target.value);
    };

    // Update parent state when input is blurred
    const handleBlur = () => {
      onUpdate(name, localValue);
    };

    const mixedPresentationOptions = [
      { value: '', label: 'Choose Style', isDisabled: true },
      { value: 'Plated', label: 'Plated' },
      { value: 'Family Style', label: 'Family Style' },
      { value: 'Buffet', label: 'Buffet' },
      { value: 'Orders Taken', label: 'Orders Taken' },
      { value: 'Other', label: 'Other' },
    ];
    const sortedOptions = mixedPresentationOptions.sort((a, b) => {
      if (a.label === 'Choose Style') return -1;
      if (b.label === 'Choose Style') return 1;
      return a.label.localeCompare(b.label);
    });
    const showInput = styleValue === 'Other';
    const handleStyleChange = (selectedOption) => {
      const selectedValue = selectedOption.value;
      setStyleValue(selectedValue);
      if (selectedValue !== 'Other' && selectedValue !== 'Buffet') {
        setBuffetSubStyle('');
      }
    };
    return (
      <div>
        <label>{label}</label>
        <input
          ref={inputRef}
          className="food-input"
          type="number"
          inputMode="numeric"
          pattern="[0-9]*"
          min="0"
          placeholder="0"
          name={name}
          value={localValue}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {presentation === 'Mixed' && value > 0 && (
          <div className='presentationDropDown'>
            <div className="select-container" style={{ zIndex: 1000 }}>
              <Select
                menuPortalTarget={document.body}
                options={mixedPresentationOptions}
                styles={customStyles}
                value={mixedPresentationOptions.find(option => option.value === styleValue)}
                onChange={handleStyleChange}
              />
            </div>
            {showInput && (
              <OtherInput
                otherValue={otherValue}
                setOtherValue={setOtherValue}
                setStyleValue={setStyleValue}
                shouldFocus={styleValue === 'Other'}
                disabled={false}
                className='otherInputBox'
              />
            )}
            {styleValue === 'Buffet' && (
              renderBuffetOptions(buffetSubStyle, setBuffetSubStyle, label)
            )}
          </div>
        )}
      </div>
    );
  };
  const InputField = ({ className, label, type, onChange, value }) => {
    return (
      <div>
        <label>{label}</label>
        <input
          type={type}
          className={className}
          onChange={onChange}
          value={value}
        />
      </div>
    );
  };
  const showForm = () => {
    setStarted(true);
  };
  const ReoccurringOptions = ['Daily', 'Weekly', 'Monthly', 'Custom'];
  const leftPadding = "27%";
  const leftPaddingNonAlcohlic = "27%";
  const handleMinInputChange = (value) => {
    const parsedValue = parseInt(value, 10);
    if (!isNaN(parsedValue) && parsedValue >= 1) {
      setMinGuest(parsedValue);
      if (parsedValue > maxGuest) {
        setMaxGuest(parsedValue);
      }
    }
  }
  const [textareaValue, setTextareaValue] = useState(''); const handleMaxInputChange = (value) => {
    const parsedValue = parseInt(value, 10);
    if (!isNaN(parsedValue) && parsedValue >= minGuest) {
      setMaxGuest(parsedValue);
    }
  }
  const [selectedValues, setSelectedValues] = useState({});
  const handleAlcoholicSubcategoryChange = (drinkType, subIndex, value, aspect) => {
    setSelectedAlcoholicDrinkTypes(prevDrinks => {
      const newDrinks = prevDrinks.map((drink, index) => {
        if (drink.type === drinkType) {
          let updatedSubcategories = [...drink.subcategories];
          updatedSubcategories[subIndex] = { ...updatedSubcategories[subIndex], [aspect]: value };
          return { ...drink, subcategories: updatedSubcategories };
        }
        return drink;
      });

      const updatedSelectedSubTypes = { ...selectedSubTypes };
      updatedSelectedSubTypes[drinkType] = newDrinks
        .find(drink => drink.type === drinkType)?.subcategories
        .map(sub => sub.type)
        .filter(type => type !== "placeholder") || [];
      setSelectedSubTypes(updatedSelectedSubTypes);
      return newDrinks;
    });
  };
  const updateSelectedTypes = () => {
    const newSelectedTypes = selectedAlcoholicDrinkTypes.map(drink => drink.type).filter(type => type !== "");
    setSelectedTypes(newSelectedTypes);
  };
  const [selectedSubcategories, setSelectedSubcategories] = useState({});
  const [selectedSubTypes, setSelectedSubTypes] = useState([]);
  const wineOptions = ["Red", "Rose", "White"]
  const [selectedSubcategoryTypes, setSelectedSubcategoryTypes] = useState([]);
  const getFilteredSubcategoryOptions = (drinkType) => {
    const allOptions = getSubcategoryOptionsForType(drinkType);
    const selectedOptions = selectedSubTypes[drinkType] || [];
    return allOptions.filter(option => !selectedOptions.includes(option));
  };
  const handleSubcategoryChange = (mainCategory, subcategory) => {

    setSelectedSubcategories(prev => ({
      ...prev,
      [mainCategory]: [...(prev[mainCategory] || []), subcategory]
    }));

    const subcategoryType = subcategory.type;
    setSelectedSubcategoryTypes(prev => [...new Set([...prev, subcategoryType])]);
  };
  const coffeeOptions = ["Decaf", "Regular"]

  const juiceOptions = [""]
  const waterOptions = ["Sparkling", "Still"]
  const beerOptions = ["Lager", "IPA", "Wheat", "Pilsner", "Porter", "Saison", "Ale", "Malt", "Stout"];
  const liquorOptions = ["Vodka", "Whiskey", "Rum", "Gin", "Mixers"];
  const BeverageDropdownSpecificDrink = ({
    beverageType,
    onChange,
    currentValue,
    options,
    placeholder = "How Many?",
    isAlcoholicSubcategory = false,
    showSpecificNumberInput,
    specificNumberValue,
    onSpecificNumberChange
  }) => {
    // Local state for handling specific number input
    const [localSpecificNumber, setLocalSpecificNumber] = useState(specificNumberValue || '');

    useEffect(() => {
      // Ensure that the local specific number is updated when the external value changes
      if (specificNumberValue !== localSpecificNumber) {
        setLocalSpecificNumber(specificNumberValue || '');
      }
    }, [specificNumberValue]);

    const handleNumberChange = (e) => {
      setLocalSpecificNumber(e.target.value);
    };

    const handleNumberBlur = () => {
      onSpecificNumberChange(localSpecificNumber);
    };

    const handleChange = (e) => {
      onChange(e.target.value);
    };

    return (
      <div className="dropdownContainer">
        <select
          className="customDropdown"
          value={currentValue}
          onChange={handleChange}
        >
          <option value="placeholder" disabled>{placeholder}</option>
          {options.map(option => (
            <option key={option} value={option}>{option}</option>
          ))}
        </select>
        {showSpecificNumberInput && currentValue === "Specific Number" && (
          <div>
            <input
              type="number"
              min="1"
              placeholder='0'
              className="custom-inputAccomodations"
              value={localSpecificNumber}
              onChange={handleNumberChange}
              onBlur={handleNumberBlur}
            />
            <label className='accomodationLabel'>
              {Number(localSpecificNumber) === 1 ? 'Person' : 'People'}
            </label>
          </div>
        )}
      </div>
    );
  };

  const BeverageDropdown = ({
    beverageType,
    onChange,
    currentValue,
    options,
    placeholder = "How Many?",
    isAlcoholicSubcategory = false,
    showSpecificNumberInput,
    specificNumberValue,
    onSpecificNumberChange
  }) => {
    // Local state for handling specific number input
    const [localSpecificNumber, setLocalSpecificNumber] = useState(specificNumberValue || '');

    useEffect(() => {
      // Ensure that the local specific number is updated when the external value changes
      if (specificNumberValue !== localSpecificNumber) {
        setLocalSpecificNumber(specificNumberValue || '');
      }
    }, [specificNumberValue]);


    const handleNumberChange = (e) => {
      setLocalSpecificNumber(e.target.value);
    };

    const handleNumberBlur = () => {
      onSpecificNumberChange(localSpecificNumber);
    };

    const defaultOptions = ["100% of Guests", "75% of Guests", "50% of Guests", "25% of Guests", "Specific Number"];
    const dropdownOptions = options || defaultOptions;
    const handleChange = (e) => {
      onChange(e.target.value);
    };

    return (
      <div className="dropdownContainer">
        <select
          className="customDropdown"
          value={currentValue}
          onChange={handleChange}
        >
          <option value="placeholder" disabled>{placeholder}</option>
          {dropdownOptions.map(option => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
        {showSpecificNumberInput && currentValue === "Specific Number" && (
          <div>
            <input
              type="number"
              min="1"
              placeholder='0'
              className="custom-inputAccomodations"
              value={localSpecificNumber}
              onChange={handleNumberChange}
              onBlur={handleNumberBlur}
            />
            <label className='accomodationLabel'>
              {Number(localSpecificNumber) === 1 ? 'Person' : 'People'}
            </label>
          </div>
        )}
      </div>
    );
  };
  const handleSpecificDrinkNumberChange = (drinkIndex, specificIndex, value) => {

    setSelectedAlcoholicDrinkTypes(prevDrinks => {
      const newDrinks = [...prevDrinks];
      if (!newDrinks[drinkIndex].specificDrinks[specificIndex]) {
        newDrinks[drinkIndex].specificDrinks[specificIndex] = {};
      }
      newDrinks[drinkIndex].specificDrinks[specificIndex].specificNumber = value;
      return newDrinks;
    });
  };
  const handleDropdownChange = (label, value) => {
    setSpecificSelections(prev => ({
      ...prev,
      [label]: value,
    }));
  };
  const handleSpecificEveryoneFewChange = (drinkIndex, specificIndex, newValue) => {
    setSelectedAlcoholicDrinkTypes(prevDrinks => {
      return prevDrinks.map((drink, idx) => {
        if (idx === drinkIndex) {
          // Ensure we are updating a copy of specificDrinks
          let newSpecificDrinks = [...drink.specificDrinks];
          if (newSpecificDrinks[specificIndex]) {
            newSpecificDrinks[specificIndex] = {
              ...newSpecificDrinks[specificIndex],
              everyoneFew: newValue
            };
          }
          return {
            ...drink,
            specificDrinks: newSpecificDrinks
          };
        }
        return drink;
      });
    });
  };


  const handleRemoveSubcategory = (setSubcategoriesFunc, indexToRemove) => {
    setSubcategoriesFunc(prevSubcategories =>
      prevSubcategories.filter((_, index) => index !== indexToRemove)
    );
  };
  const [selectedDrinkTypes, setSelectedDrinkTypes] = useState([
    { type: "", subcategories: [{ type: "placeholder", everyoneFew: "placeholder" }], specificDrinks: [] }
  ]);
  function addMoreDrinkTypes() {
    setSelectedDrinkTypes([...selectedDrinkTypes, { type: "" }]);
  }
  const [selectedAlcoholicDrinkTypes, setSelectedAlcoholicDrinkTypes] = useState([
    { type: "", subcategories: [{ type: "placeholder", everyoneFew: "placeholder" }], specificDrinks: [] }
  ]); const addMoreAlcoholicDrinkTypes = () => {
    setSelectedAlcoholicDrinkTypes(prevDrinks => [
      ...prevDrinks,
      { type: "", subcategories: [{ type: "placeholder", everyoneFew: "placeholder" }] }
    ]);
  };
  const AlcDrinkOptions = ["Coffee", "Juice", "Water", "Beer", "Liquor", "Wine", "Specific Drink"];
  const [drinkDetails, setDrinkDetails] = useState({});

  const textareaRef = useRef(null);
  const handleTextareaChange = (index, value) => {
    const updatedDetails = { ...drinkDetails };
    updatedDetails[index] = value;
    setDrinkDetails(updatedDetails);

    if (textareaRef.current) {
      textareaRef.current.style.height = "inherit";
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = scrollHeight + "px";
    }
  };
  const adjustHeight = (elem) => {
    elem.style.height = 'auto';
    elem.style.height = Math.min(elem.scrollHeight, 40) + 'px';
  };

  const handleAlcoholicDrinkTypeChange = (index, value) => {
    setSelectedAlcoholicDrinkTypes(prevDrinks => {
      const newDrinks = [...prevDrinks];
      if (value === "Specific Drink" && (!newDrinks[index].specificDrinks || newDrinks[index].specificDrinks.length === 0)) {
        newDrinks[index].specificDrinks = [{ details: "", everyoneFew: "placeholder" }];
      } else if (value !== "Specific Drink") {
        newDrinks[index].specificDrinks = [];
      }

      newDrinks[index].type = value;
      return newDrinks;
    });
  };
  const addSubcategory = (drinkIndex) => {
    setSelectedAlcoholicDrinkTypes(prevDrinks => prevDrinks.map((drink, index) => {
      if (index === drinkIndex) {
        return {
          ...drink,
          subcategories: [...drink.subcategories, { type: "placeholder", everyoneFew: "placeholder" }]
        };
      }
      return drink;
    }));
  };
  const getSubcategoryOptionsForType = (drinkType) => {
    const optionsForType = allSubcategoryOptions[drinkType] || [];
    const selectedOptions = selectedSubTypes[drinkType] || [];
    return optionsForType.filter(option => !selectedOptions.includes(option));
  };
  const [specificAlcoholicDrinkDetails, setSpecificAlcoholicDrinkDetails] = useState({});
  const handleAlcoholicSpecificDrinkChange = (index, value) => {
    setSpecificAlcoholicDrinkDetails(prev => ({
      ...prev,
      [index]: value
    }));
  };

  function adjustTextareaRows(textarea) {
    const maxRows = 2;
    const lines = textarea.value.split('\n').length;
    textarea.rows = Math.min(lines, maxRows);
  }
  const labels = ['Cutlery', 'Plates', 'Napkins', 'Linen', 'Tents', 'Food Tables', 'Clearing Plates', 'Washing Dishes'];
  const options = ['Caterer', 'Host'];
  const [providedBy, setProvidedBy] = useState({
    'Tents': '',
    'Food Tables': '',
    'Cutlery': '',
    'Clearing Plates': '',
    'Washing Dishes': '',
    "Plates": '',
    "Disposable Plates": '',
    'Linen': '',
    'Napkins': ''
  });
  const [reusableEquipmentSelected, setReusableEquipmentSelected] = useState(false);
  const [equipmentReturnOption, setEquipmentReturnOption] = useState('');

  useEffect(() => {
    const hasReusableEquipment = Object.values(providedBy).includes("Reusable");
    setReusableEquipmentSelected(hasReusableEquipment);
  }, [providedBy]);


  const handleProvidedByChange = (label, value) => {
    setProvidedBy(prevState => {
      if (prevState[label] === value) {

        return { ...prevState, [label]: '' };
      } else {

        return { ...prevState, [label]: value };
      }
    });
  };
  const [kitchenOptions, setKitchenOptions] = useState([{ type: 'placeholder' }]);
  const [potableWater, setPotableWater] = useState(null); const kitchenDropdownOptions = ['Industrial Kitchen', 'Home Kitchen', 'No Kitchen', 'Undetermined'];
  const handleKitchenChange = (index, value) => {
    const newOptions = [...kitchenOptions];
    newOptions[index].type = value;
    setKitchenOptions(newOptions);
    if (value === 'No Kitchen') {
      setPotableWater(null);
    }
  };
  const US_STATES = [
    'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia',
    'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland',
    'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey',
    'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina',
    'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
  ];
  const resetOption = (setOptionsFunction, index) => {
    const newOptions = [{ type: 'placeholder' }];
    setOptionsFunction(newOptions);
  };
  const serviceWareOptions = ['Service Ware', 'Service Ware Disposable', 'Service Ware Reusable'];
  const cutleryOptionsRD = ['Cutlery', 'Disposable Cutlery', 'Reusable Cutlery'];
  const platesOptions = ['Plates', 'Disposable Plates', 'Reusable Plates'];
  const napkinsOptions = ['Napkins', 'Disposable Napkins', 'Reusable Napkins'];
  const linenOptions = ['Linen', 'Disposable Linen', 'Reusable Linen'];

  const [specificSelections, setSpecificSelections] = useState({
    Cutlery: '',
    Plates: '',
    Napkins: '',
    Linen: '',
  });
  const equipmentLabels = ['Cutlery', 'Plates', 'Napkins', 'Linen'];

  const EquipmentLayout = ({ presentation }) => (
    <div>
      <h3>Equipment</h3>
      <div className={`${presentation !== 'Pick Up' ? 'provided-by-grid' : 'provided-by-gridServices'}`}>
        <div><label className="visually-hidden">Label</label></div>
        <div>Disposable</div>
        {presentation !== 'Pick Up' && (
          <div className='DLeft'>Reusable</div>
        )}
        {equipmentLabels.map((label, idx) => (
          <React.Fragment key={idx}>
            <div>{label}</div>
            <div>
              <input
                className="custom-checkbox"
                type="checkbox"
                name={`providedBy-${label}-Disposable`}
                value="Disposable"
                checked={providedBy[label] === "Disposable"}
                onChange={() => handleProvidedByChange(label, "Disposable")}
              />
            </div>
            {presentation !== 'Pick Up' && (
              <div>
                <input
                  className="custom-checkbox"
                  type="checkbox"
                  name={`providedBy-${label}-Reusable`}
                  value="Reusable"
                  checked={providedBy[label] === "Reusable"}
                  onChange={() => handleProvidedByChange(label, "Reusable")}
                />
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );

  const serviceLabels = ['Tents', 'Food Tables', 'Clearing Plates', 'Washing Dishes'];

  const servicesLayout = (
    <div>
      <h3>Services</h3>
      <div className="provided-by-gridServices">
        <div><label className="visually-hidden">Label</label></div>
        <div><label className="visually-hidden">Selected</label></div>
        {serviceLabels.map((label, idx) => (
          <React.Fragment key={idx}>
            <div>{label}</div>
            <div>
              <input
                className="custom-checkbox"
                type="checkbox"
                name={`providedBy-${label}`}
                value="Requested"
                checked={providedBy[label] === "Requested"}
                onChange={() => handleProvidedByChange(label, "Requested")}
              />
            </div>
            <div className="visually-hidden"></div> {/* Keeps grid alignment */}
          </React.Fragment>
        ))}
      </div>
    </div>
  );


  const [selections, setSelections] = useState({
    Cutlery: { provider: '', option: '' },
    Plates: { provider: '', option: '' },
    Napkins: { provider: '', option: '' },
    Linen: { provider: '', option: '' },

  });

  const [specificNumber, setSpecificNumber] = useState("");
  const handleSpecificNumberChange = (beverageType, newValue) => {
    setSpecificNumbers(prevNumbers => {
      const updatedNumbers = {
        ...prevNumbers,
        [beverageType]: newValue,
      };
      return updatedNumbers;
    });
  };
  const renderAlcoholicDrinkCards = () => {
    return selectedAlcoholicDrinkTypes.map((drink, index) => {
      const subcategoryOptions = getSubcategoryOptionsForType(drink.type);
      const nonPlaceholderOptions = subcategoryOptions.filter(option => option !== "placeholder");
      const hasNonPlaceholderOptionsAvailable = nonPlaceholderOptions.length > 0;
      const hasPlaceholderSubcategory = drink.subcategories.some(subcategory => subcategory.type === "placeholder");
      const shouldRenderCard = hasValidOptions() || drink.type !== "";
      const isNotPlaceholder = drink.type !== "" && drink.type !== "placeholder";

      let buttonText = `+ ${drink.type}`;
      if (nonPlaceholderOptions.length === 1) {
        buttonText = `+ ${nonPlaceholderOptions[0]}`;
      }
      return shouldRenderCard ? (<div className="drinkCard" key={index}>
        <div className="cardHeader">
          {drink.type !== "" && (
            <span
              className="removeOptionCategory"
              onClick={() => {
                if (index === 0) {

                  const resetDrink = { ...drink, type: "", subcategories: [{ type: "placeholder", everyoneFew: "placeholder" }] };
                  setSelectedAlcoholicDrinkTypes([resetDrink, ...selectedAlcoholicDrinkTypes.slice(1)]);
                } else {

                  const newSelectedAlcoholicDrinkTypes = [...selectedAlcoholicDrinkTypes];
                  newSelectedAlcoholicDrinkTypes.splice(index, 1);
                  setSelectedAlcoholicDrinkTypes(newSelectedAlcoholicDrinkTypes);
                }
              }}
            >
              X
            </span>
          )}
          <select
            className={`mainDropdown ${drink.type !== "" ? 'selected' : ''}`}
            value={drink.type}
            onChange={(e) => handleAlcoholicDrinkTypeChange(index, e.target.value)}
            disabled={drink.type !== ""}
          >
            <option value="" disabled>Drink Options&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</option>
            {AlcDrinkOptions.filter(option =>
              !selectedTypes.includes(option) || option === drink.type
            ).map(option => (
              <option
                key={option}
                value={option}
              >
                {option.charAt(0).toUpperCase() + option.slice(1)}
              </option>
            ))}
          </select>
        </div>
        {
          allSubcategoryOptions[drink.type] &&
          renderSubcategories(drink.type, drink.subcategories, allSubcategoryOptions[drink.type], selectedSubTypes, index, specificNumber, setSpecificNumber)
        }
        <div className="dropdownSection"> {/* Dropdown section of the card */}
          {drink.type === "Specific Drink" && drink.specificDrinks && (
            <>
              {drink.specificDrinks.map((specificDrink, specificIndex) => (
                <div className="beverage-dropdowns" key={`specificDrink_${index}_${specificIndex}`} >
                  {drink.specificDrinks.length > 1 && (
                    <span
                      className="removeOption" // Ensure this class includes `cursor: pointer;` and any necessary positioning styles
                      onClick={() => removeSpecificDrink(index, specificIndex)}
                      role="button" // Accessibility improvement
                      tabIndex="0" // Make it focusable
                      onKeyPress={(e) => e.key === 'Enter' && removeSpecificDrink(index, specificIndex)} // Accessibility: remove on Enter key
                    >
                      X
                    </span>
                  )}
                  <textarea
                    placeholder="Add Details"
                    value={specificDrink.details || ''}
                    onChange={(e) => {
                      handleSpecificDrinkDetailsChange(index, specificIndex, e.target.value);
                      adjustTextareaRows(e.target);
                    }}
                    className='otherInputBoxDrink'
                    style={{ overflow: "auto", resize: "none", maxWidth: "90%", lineHeight: "20px" }}
                    rows={1}
                  />
                  <BeverageDropdownSpecificDrink
                    key={`specificDrink_${index}_${specificIndex}`}
                    beverageType={`${drink.type}_${index}_${specificIndex}`}
                    onChange={(value) => handleSpecificEveryoneFewChange(index, specificIndex, value)}
                    currentValue={specificDrink.everyoneFew || "placeholder"}
                    options={["100% of Guests", "75% of Guests", "50% of Guests", "25% of Guests", "Specific Number"]}
                    // placeholder="How Many?"
                    showSpecificNumberInput={specificDrink.everyoneFew === "Specific Number"}
                    specificNumberValue={specificNumbers[`${drink.type}_${index}_${specificIndex}`]}
                    onSpecificNumberChange={(newValue) => handleSpecificNumberChange(`${drink.type}_${index}_${specificIndex}`, newValue)}
                  />

                  {specificIndex < drink.specificDrinks.length - 1 && (
                    <div className="dropdown-separator"></div>
                  )}
                </div>
              ))}
              {drink.specificDrinks.length === 0 || drink.specificDrinks[drink.specificDrinks.length - 1].details !== "" ? (
                <button className='drinkButton' onClick={() => handleAddSpecificDrink(index)}>+ Drink</button>
              ) : null}
            </>
          )}
        </div>
        {hasNonPlaceholderOptionsAvailable && !hasPlaceholderSubcategory && isNotPlaceholder && (
          <button className="button-margin-left" onClick={() => addSubcategory(index)}>{buttonText}</button>
        )}
      </div>
      ) : null;
    });
  };
  const handleAddSpecificDrink = (index) => {
    setSelectedAlcoholicDrinkTypes(prevDrinks => {
      const newDrinks = [...prevDrinks];

      if (!Array.isArray(newDrinks[index].specificDrinks) || newDrinks[index].specificDrinks.length === 0
        || newDrinks[index].specificDrinks[newDrinks[index].specificDrinks.length - 1].details !== "") {

        newDrinks[index].specificDrinks.push({ details: "", everyoneFew: "placeholder" });
      }
      return newDrinks;
    });
  };
  const handleSpecificDrinkDetailsChange = (drinkIndex, specificDrinkIndex, newValue) => {
    setSelectedAlcoholicDrinkTypes(prevDrinks => {

      const newDrinks = [...prevDrinks];

      if (newDrinks[drinkIndex] && newDrinks[drinkIndex].specificDrinks && newDrinks[drinkIndex].specificDrinks[specificDrinkIndex]) {

        newDrinks[drinkIndex].specificDrinks[specificDrinkIndex] = {
          ...newDrinks[drinkIndex].specificDrinks[specificDrinkIndex],
          details: newValue
        };
      }
      return newDrinks;
    });
  };
  const [specificNumbers, setSpecificNumbers] = useState({});
  const allDrinksNotPlaceholder = selectedAlcoholicDrinkTypes.every(drink => drink.type !== "" && drink.type !== "placeholder");
  const renderSubcategories = (drinkType, subcategories, allSubcategoryOptions, selectedSubTypes, drinkIndex, specificNumber, setSpecificNumber) => {
    const handleSpecificNumberChange = (drinkType, drinkIndex, subcategoryIndex, newValue) => {
      setSpecificNumbers(prevNumbers => {
        const key = `${drinkType}_${drinkIndex}_${subcategoryIndex}`;
        return {
          ...prevNumbers,
          [key]: newValue,
        };
      });
    };


    const subcategoryElements = subcategories.map((subcategory, subIndex) => {
      const isSpecificNumberSelected = subcategory.everyoneFew === "Specific Number";
      const selectedOptions = selectedSubTypes[drinkType] || [];

      const availableOptions = allSubcategoryOptions.filter(option =>
        !selectedOptions.includes(option) || option === subcategory.type
      );
      const validOptions = allSubcategoryOptions.filter(option => option.trim() !== "");

      const placeholderText = availableOptions.length === 1 ? availableOptions[0] : "Type";
      return (
        <div className="dropdownSection beverage-dropdowns" key={`${drinkType}_${subIndex}`}>
          {subcategories.length > 1 && (
            <span
              className="removeOption"
              onClick={(event) => removeSubcategory(drinkIndex, subIndex, event)}
            >
              X
            </span>
          )}

          {validOptions.length > 0 && (
            <BeverageDropdown
              onChange={(value) => handleAlcoholicSubcategoryChange(drinkType, subIndex, value, 'type')}
              currentValue={subcategory.type}
              options={availableOptions}
              placeholder={placeholderText}
            />
          )}
          <BeverageDropdown
            onChange={(value) => handleAlcoholicSubcategoryChange(drinkType, subIndex, value, 'everyoneFew')}
            currentValue={subcategory.everyoneFew}
            showSpecificNumberInput={subcategory.everyoneFew === "Specific Number"}
            specificNumberValue={specificNumbers[`${drinkType}_${drinkIndex}_${subIndex}`]}
            onSpecificNumberChange={(newValue) => handleSpecificNumberChange(drinkType, drinkIndex, subIndex, newValue)}
          />


          {subIndex < subcategories.length - 1 && <div className="dropdown-separator"></div>}
        </div>
      );
    });

    const anyAvailableOptions = allSubcategoryOptions.some(option =>
      !selectedSubTypes[drinkType]?.includes(option)
    );
    return (
      <>
        {subcategoryElements}
      </>
    );
  };
  const removeSubcategory = (mainIndex, subIndex, event) => {
    event.stopPropagation();
    let removedSubcategoryType = null;
    setSelectedAlcoholicDrinkTypes(prevDrinks => {
      const updatedDrinks = prevDrinks.map((drink, index) => {
        if (index === mainIndex) {
          removedSubcategoryType = drink.subcategories[subIndex].type;
          return {
            ...drink,
            subcategories: drink.subcategories.filter((_, idx) => idx !== subIndex)
          };
        }
        return drink;
      });

      if (removedSubcategoryType) {
        setSelectedSubTypes(prevSelectedSubTypes => {
          const updatedSelectedSubTypes = { ...prevSelectedSubTypes };
          const currentDrinkType = updatedDrinks[mainIndex].type;
          updatedSelectedSubTypes[currentDrinkType] = updatedSelectedSubTypes[currentDrinkType].filter(type => type !== removedSubcategoryType);
          return updatedSelectedSubTypes;
        });
      }
      return updatedDrinks;
    });
  };
  const removeSpecificDrink = (drinkIndex, specificDrinkIndex) => {
    setSelectedAlcoholicDrinkTypes(prevDrinks => {
      return prevDrinks.map((drink, idx) => {
        if (idx === drinkIndex) {
          // Remove the specificDrink at specificDrinkIndex
          const updatedSpecificDrinks = drink.specificDrinks.filter((_, sIndex) => sIndex !== specificDrinkIndex);
          return { ...drink, specificDrinks: updatedSpecificDrinks };
        }
        return drink;
      });
    });
  };



  const [selectedTypes, setSelectedTypes] = useState([]);
  const updateSelectedSubTypes = () => {
    const newSelectedSubTypes = selectedAlcoholicDrinkTypes.reduce((acc, drink) => {
      const subcategoryTypes = drink.subcategories
        .filter(sub => sub.type !== "placeholder")
        .map(sub => sub.type);
      acc[drink.type] = subcategoryTypes;
      return acc;
    }, {});
    setSelectedSubTypes(newSelectedSubTypes);
  };
  const allSubcategoryOptions = {
    Beer: beerOptions,
    Liquor: liquorOptions,
    Wine: wineOptions,
    Coffee: coffeeOptions,
    Juice: juiceOptions,
    Water: waterOptions
  };
  const hasValidOptions = () => {

    return AlcDrinkOptions.some(option => !selectedTypes.includes(option));
  };
  useEffect(() => {
    updateSelectedTypes();
  }, [selectedAlcoholicDrinkTypes]);



  const MinBudgets = '';
  const [minBudget, setMinBudget] = React.useState(MinBudgets);
  const [maxBudget, setMaxBudget] = React.useState(MinBudgets);
  const handleMinBudgetChange = (value) => {
    const parsedValue = parseInt(value, 10);
    if (!isNaN(parsedValue) && parsedValue >= 0) {
      setMinBudget(parsedValue.toString());
      if (parsedValue > maxBudget) {
        setMaxBudget(parsedValue.toString());
      }
    } else if (value === '') {
      setMinBudget('');
    }
  };
  const handleMaxBudgetChange = (value) => {
    const parsedValue = parseInt(value, 10);
    if (!isNaN(parsedValue)) {
      setMaxBudget(parsedValue.toString());
    } else if (value === '') {
      setMaxBudget('');
    }
  };
  const handleMaxBudgetBlur = () => {
    if (maxBudget === '' || maxBudget < minBudget) {
      setMaxBudget(minBudget);
    }
  };
  const MinAdultGuests = '';
  const [minAdultGuest, setMinAdultGuest] = React.useState(MinAdultGuests);
  const [maxAdultGuest, setMaxAdultGuest] = React.useState(MinAdultGuests);
  const MinChildGuests = '';
  const [minChildGuest, setMinChildGuest] = React.useState(MinChildGuests);
  const [maxChildGuest, setMaxChildGuest] = React.useState(MinChildGuests);
  const handleFocus = (setPlaceholder) => {
    setPlaceholder("");
  };
  const handleFocusBudget = (setPlaceholder) => {
    setPlaceholder("");
  };
  const handleBlur = (value, setPlaceholder, setGuestValue, minGuestValue) => {
    if (value === "") {
      setPlaceholder("0");
      setGuestValue(minGuestValue);
    }
  };
  const handleMinAdultGuestChange = (value) => {
    const parsedValue = parseInt(value, 10);
    if (!isNaN(parsedValue) && parsedValue >= 0) {
      setMinAdultGuest(parsedValue.toString());
      if (parsedValue > maxAdultGuest) {
        setMaxAdultGuest(parsedValue.toString());
      }
    } else if (value === '') {
      setMinAdultGuest('');
    }
  };
  const handleMaxAdultGuestChange = (value) => {
    const parsedValue = parseInt(value, 10);
    if (!isNaN(parsedValue)) {
      setMaxAdultGuest(parsedValue.toString());
    } else if (value === '') {
      setMaxAdultGuest('');
    }
  };
  const handleMaxAdultGuestBlur = () => {
    if (maxAdultGuest === '' || maxAdultGuest < minAdultGuest) {
      setMaxAdultGuest(minAdultGuest);
    }
  };
  const handleMaxChildGuestBlur = () => {
    if (maxChildGuest === '' || maxChildGuest < minChildGuest) {
      setMaxChildGuest(minChildGuest);
    }
  };
  const handleMinChildGuestChange = (value) => {
    const parsedValue = parseInt(value, 10);
    if (!isNaN(parsedValue) && parsedValue >= 0) {
      setMinChildGuest(parsedValue.toString());
      if (parsedValue > maxChildGuest) {
        setMaxChildGuest(parsedValue.toString());
      }
    } else if (value === '') {
      setMinChildGuest('');
    }
  };
  const handleMaxChildGuestChange = (value) => {
    const parsedValue = parseInt(value, 10);
    if (!isNaN(parsedValue)) {
      setMaxChildGuest(parsedValue.toString());
    } else if (value === '') {
      setMaxChildGuest('');
    }
  };

  const [streetAddress, setStreetAddress] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [venueNotes, setVenueNotes] = useState('');
  const [additionalNotes, setAdditionalNotes] = useState('');
  const handleStreetAddressChange = (event) => {
    setStreetAddress(event.target.value);
  };
  const handleZipCodeChange = (event) => {
    setZipCode(event.target.value);
  };
  const handleNumberInputChange = (e) => {
    const inputElement = e.target;
    const value = inputElement.value;

    inputElement.size = Math.max(value.length, 1);
  };
  



  useEffect(() => {
    // If only one method is available, auto-select it
    if (availableMethods.length === 1) {
      // e.g. if availableMethods[0] === 'delivered'
      // we set the state to "Delivered" or whichever
      const single = availableMethods[0];
      if (single === 'delivered') {
        setFoodDeliveryOption('Delivered');
      } else if (single === 'droppedOff') {
        setFoodDeliveryOption('DropOff');
      } else if (single === 'pickedUp') {
        setFoodDeliveryOption('Pick Up');
      }
    }
  }, [availableMethods]);

  return (
    <div >
      <>
        <div>
          <h3>Guest Count</h3>
        </div>
        <div className="">
          <div className="drinkSelectionContainerCutlery">
            <div className="drinkCardGC ">
              <div className="input-group">Adults&nbsp;</div>
              <label className="input-group">
                Min:
                <input
                  type="number"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  placeholder="0"
                  className="custom-input"
                  value={minAdultGuest}
                  onFocus={handleFocus}
                  onChange={e => handleMinAdultGuestChange(e.target.value)} />
              </label>
              <label className="input-group">
                Max:
                <input
                  inputMode="numeric"
                  pattern="[0-9]*"
                  type="number"
                  placeholder="0"
                  className="custom-input"
                  value={maxAdultGuest}
                  min={minAdultGuest}
                  onChange={e => handleMaxAdultGuestChange(e.target.value)}
                  onBlur={handleMaxAdultGuestBlur} />
              </label>
            </div>
            <div className="drinkCardGC ">
              <div className="input-group">Children&nbsp;</div>
              <label className="input-group">
                Min:
                <input
                  inputMode="numeric"
                  pattern="[0-9]*"
                  type="number"
                  placeholder="0"
                  className="custom-input"
                  value={minChildGuest}
                  onChange={e => handleMinChildGuestChange(e.target.value)}
                />
              </label>
              <label className="input-group">
                Max:
                <input
                  inputMode="numeric"
                  pattern="[0-9]*"
                  type="number"
                  placeholder="0"
                  className={`custom-input`}
                  value={maxChildGuest}
                  onChange={e => handleMaxChildGuestChange(e.target.value)}
                  onBlur={handleMaxChildGuestBlur} />
              </label>
            </div>
          </div>
        </div>
        <div className="accommodations-container">
          <div className="drinkCardGuest">
            <div className="venue-field">

              <h3>Food</h3>

              {/* If only 1 method is true: just show text "Delivered & Served," etc. */}
              {availableMethods.length === 1 && (
                <>
                  {availableMethods[0] === 'delivered' && (
                    <p>Delivered & Served</p>
                  )}
                  {availableMethods[0] === 'droppedOff' && (
                    <p>Dropped Off</p>
                  )}
                  {availableMethods[0] === 'pickedUp' && (
                    <p>Picked Up</p>
                  )}
                </>
              )}

              {/* If more than 1 method is true: show radio options */}
              {availableMethods.length > 1 && (
                <div>
                  {deliveryMethods.delivered && (
                    <div>
                      <input
                        type="radio"
                        id="delivered"
                        name="foodDeliveryOption"
                        value="Delivered"
                        checked={foodDeliveryOption === "Delivered"}
                        onChange={() => setFoodDeliveryOption("Delivered")}
                      />
                      <label htmlFor="delivered">Delivered & Served</label>
                    </div>
                  )}
                  {deliveryMethods.droppedOff && (
                    <div>
                      <input
                        type="radio"
                        id="dropOff"
                        name="foodDeliveryOption"
                        value="DropOff"
                        checked={foodDeliveryOption === "DropOff"}
                        onChange={() => setFoodDeliveryOption("DropOff")}
                      />
                      <label htmlFor="dropOff">Dropped Off</label>
                    </div>
                  )}
                  {deliveryMethods.pickedUp && (
                    <div>
                      <input
                        type="radio"
                        id="pickup"
                        name="foodDeliveryOption"
                        value="Pick Up"
                        checked={foodDeliveryOption === "Pick Up"}
                        onChange={() => setFoodDeliveryOption("Pick Up")}
                      />
                      <label htmlFor="pickup">Picked Up</label>
                    </div>
                  )}
                </div>
              )}

              {/* If there's only 1 method, we've automatically selected it, 
    so no need to show "Make selection to continue." */}
              {(availableMethods.length > 1 && !foodDeliveryOption) && (
                <div className='required-row'>
                  <span className='required-text'>Make selection to continue</span>
                </div>
              )}
            </div>
          </div>
        </div>
        {(foodDeliveryOption === 'Delivered' || foodDeliveryOption === 'Pick Up' || foodDeliveryOption === 'DropOff') && (
          <>
            {foodDeliveryOption === 'Delivered' && (
              <>
                <div className='accommodations-container'>
                  <div className="drinkCardGuest">
                    <h3>Presentation</h3>
                    <div className='eveFewAccomodation'>
                      <select
                        className="customDropdownAccomodations"
                        value={presentation}
                        onChange={(e) => setPresentation(e.target.value)}
                      >
                        <option value="" disabled>Options</option> {/* Placeholder option */}
                        <option value="Plated">Plated</option>
                        <option value="Buffet">Buffet</option>
                        <option value="Family Style">Family Style</option>
                        <option value="Other">Other</option>
                      </select>

                      {presentation === 'Plated' && (
                        <div className='input-groupPRESENTATION'>
                          <label>Courses:</label>
                          <input
                            className="custom-input"
                            type="number"
                            min="1"
                            placeholder='0'
                            value={courses}
                            onChange={handleChange(setCourses)}
                          />
                        </div>
                      )}

                      {presentation === 'Buffet' && (
                        <div className="presentation-box-Buffet">
                          {renderBuffetOptions(buffetSubStyle, setBuffetSubStyle, 'Presentation')}
                        </div>
                      )}

                      {presentation === 'Family Style' && (
                        <div className='input-groupPRESENTATION'>
                          <label>People Per Table:</label>
                          <input
                            className="custom-input"
                            type="number"
                            min="1"
                            placeholder='0'
                            max={people}
                            value={ppTable}
                            onChange={handleChange(setppTable)}
                          />
                        </div>
                      )}

                      {presentation === 'Other' && (
                        <textarea
                          placeholder="Specify"
                          value={otherPresentation}
                          onChange={(e) => {
                            setOtherPresentation(e.target.value);
                            adjustTextareaRows(e.target);
                          }}
                          className='otherInputBoxDrink'
                          style={{ overflow: "auto", resize: "none", maxWidth: "90%", lineHeight: "20px" }}
                          rows={1}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className='accommodations-container'>
              <div className='drinkCardGuest'>
                <EquipmentLayout presentation={foodDeliveryOption} />
              </div>
            </div>
            {reusableEquipmentSelected && foodDeliveryOption === 'DropOff' && (
              <div className='accommodations-container'>
                <div className='drinkCardGuest'>
                  <h3>Equipment Return</h3>
                  <p>How will reusable equipment get back to caterer?</p>
                  <div>
                    <input
                      type="radio"
                      id="returnByClient"
                      name="equipmentReturnOption"
                      value="ReturnByClient"
                      checked={equipmentReturnOption === "ReturnByClient"}
                      onChange={(e) => setEquipmentReturnOption(e.target.value)}
                    />
                    <label htmlFor="returnByClient">Returned by client</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="pickedUp"
                      name="equipmentReturnOption"
                      value="PickedUp"
                      checked={equipmentReturnOption === "PickedUp"}
                      onChange={(e) => setEquipmentReturnOption(e.target.value)}
                    />
                    <label htmlFor="pickedUp">Picked up by caterer</label>
                  </div>
                </div>
              </div>
            )}


            <div className='accommodations-container'>
              <div className='drinkCardGuest drinkCardGuestFood'>
                <h3>Food Selection</h3>
                <h5 className="centered-header">Options<br></br>Per Person</h5>  {/* Centered Header */}
                <div className='food-options'>
                  <FoodSelection
                    label="Appetizers"
                    name="appetizers"
                    initialvalue={foodSelections.appetizers}
                    onUpdate={handleFoodSelectionUpdate}
                    styleValue={appetizersStyle}
                    setStyleValue={setAppetizersStyle}
                    otherValue={appetizersOther}
                    setOtherValue={setAppetizersOther}
                    buffetSubStyle={appetizersBuffetSubStyle}
                    setBuffetSubStyle={setAppetizersBuffetSubStyle}
                  />
                  <FoodSelection
                    label="Breads"
                    name="bread"
                    initialvalue={foodSelections.bread}
                    onUpdate={handleFoodSelectionUpdate}
                    styleValue={breadStyle}
                    setStyleValue={setBreadStyle}
                    otherValue={breadOther}
                    setOtherValue={setBreadOther}
                    buffetSubStyle={breadBuffetSubStyle}
                    setBuffetSubStyle={setBreadBuffetSubStyle} />
                  <FoodSelection
                    label="Salads"
                    name="salads"
                    initialvalue={foodSelections.salads}
                    onUpdate={handleFoodSelectionUpdate}
                    styleValue={saladsStyle}
                    setStyleValue={setSaladsStyle}
                    otherValue={saladsOther}
                    setOtherValue={setSaladsOther}
                    buffetSubStyle={saladsBuffetSubStyle}
                    setBuffetSubStyle={setSaladsBuffetSubStyle}
                  />
                  <div>
                    <FoodSelection
                      label="Sides"
                      name="sides"
                      initialvalue={foodSelections.sides}
                      onUpdate={handleFoodSelectionUpdate}
                      styleValue={sidesStyle}
                      setStyleValue={setSidesStyle}
                      otherValue={sidesOther}
                      setOtherValue={setSidesOther}
                      buffetSubStyle={sidesBuffetSubStyle}
                      setBuffetSubStyle={setSidesBuffetSubStyle}
                    />
                  </div>
                  <FoodSelection
                    label="Entrées"
                    name="entree"
                    initialvalue={foodSelections.entree}
                    onUpdate={handleFoodSelectionUpdate}
                    styleValue={entreeStyle}
                    setStyleValue={setEntreeStyle}
                    otherValue={entreeOther}
                    setOtherValue={setEntreeOther}
                    buffetSubStyle={entreeBuffetSubStyle}
                    setBuffetSubStyle={setEntreeBuffetSubStyle}
                  />
                  <FoodSelection
                    label="Desserts"
                    name="dessert"
                    initialvalue={foodSelections.dessert}
                    onUpdate={handleFoodSelectionUpdate}
                    styleValue={dessertStyle}
                    setStyleValue={setDessertStyle}
                    otherValue={dessertOther}
                    setOtherValue={setDessertOther}
                    buffetSubStyle={dessertBuffetSubStyle}
                    setBuffetSubStyle={setDessertBuffetSubStyle}
                  />
                </div>
                <textarea
                  ref={textareaRef}
                  placeholder="Specific Request"
                  value={textareaValue}
                  onChange={handleChangeFood}
                  className='otherInputBoxDrink'
                  rows={1}
                />
              </div>
            </div>
            <div>
            </div>
            <h3>Beverages</h3>
            <div className="drinkSelectionContainer">
              <br></br>
              {renderAlcoholicDrinkCards()}
            </div>
            <div className="plusMore">
              {hasValidOptions() && allDrinksNotPlaceholder && (
                <button onClick={addMoreAlcoholicDrinkTypes}>+ More</button>
              )}
            </div>
            <div className="foodFormContainer">
              {renderFoodAccommodationsCard()}
            </div>

            <>
              <div className=' accommodations-container '>
                <div className='drinkCardGuest'>
                  <h3>Frequency</h3>
                  <div>
                    <label>
                      <input
                        type="radio"
                        name="event"
                        value="One time event"
                        checked={event === 'One time event'}
                        onChange={() => setEvent('One time event')}
                      />
                      One Time Event
                    </label>
                    <br />
                    <label>
                      <input
                        type="radio"
                        name="event"
                        value="Reoccurring"
                        checked={event === 'Reoccurring'}
                        onChange={() => setEvent('Reoccurring')}
                      />
                      Reoccurring
                    </label>
                  </div>
                  <div>
                    {event === 'One time event' && (
                      <div className='reocurringX'>
                        <label>
                          Date of event&nbsp;
                          <input
                            className='customDropdownTimeline'
                            type="date"
                            value={date}
                            onChange={handleDateChange}
                          />
                        </label>
                        {date && (<span
                          className="removeOptionTimeLine"
                          onClick={() => setDate('')}
                        >
                          X
                        </span>
                        )}
                      </div>
                    )}
                  </div>
                  {event === 'Reoccurring' && (
                    <>
                      <div className='reocurringX'>
                        <InputField
                          className='customDropdownTimeline' label="First Event&nbsp;"
                          type="date"
                          onChange={(e) => setFirstEvent(e.target.value)}
                          value={firstEvent}
                        />
                        {firstEvent && (
                          <span
                            className="removeOptionTimeLine"
                            onClick={() => setFirstEvent('')}
                          >
                            X
                          </span>
                        )}
                      </div>
                      <div className='reocurringX'>
                        <InputField
                          label="Last Event&nbsp;&nbsp;"
                          type="date"
                          onChange={(e) => setLastEvent(e.target.value)}
                          value={lastEvent}
                          className='customDropdownTimeline' />
                        {lastEvent && (
                          <span
                            className="removeOptionTimeLine"
                            onClick={() => setLastEvent('')}
                          >
                            X
                          </span>
                        )}
                      </div>
                      <div className='reocurringXFrequency'>
                        <select
                          className="customDropdownTimeline"
                          value={recurringFrequency} onChange={(e) => setRecurringFrequency(e.target.value)}>                      <option value="">Select frequency</option>
                          <option value="Every day">Every day</option>
                          <option value="Once a week">Once a week</option>
                          <option value="Once a month">Once a month</option>
                          <option value="Annual">Annual</option>
                          <option value="Custom">Custom</option>
                        </select>
                        {recurringFrequency && (
                          <span
                            className="removeOptionTimeLineFrequency"
                            onClick={() => setRecurringFrequency('')}
                          >
                            X
                          </span>
                        )}
                      </div>
                      {recurringFrequency === 'Custom' && (
                        <div>
                          <textarea
                            rows='1'
                            placeholder="Specify"
                            onChange={(e) => {
                              setCustomFrequency(e.target.value);
                              adjustTextareaRows(e.target);
                            }}
                            value={customFrequency}
                            className='otherInputBoxDrink'
                            style={{ overflow: "auto", resize: "none", maxWidth: "90%", lineHeight: "20px" }}
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>

              <div className='accommodations-container'>
                <div className='drinkCardGuest'>
                  <div>
                    <h3>Time Line</h3>

                    {foodDeliveryOption === 'Pick Up' && (
                      <div className="venue-field">
                        <label>When will you be picking up the food?&nbsp;</label>
                        <input
                          className='customDropdownTimeline'
                          type="time"
                          onChange={(e) => setPickUpTime(e.target.value)}
                          value={pickUpTime}
                        />
                        {pickUpTime && (<span
                          className="removeOptionTimeLine"
                          onClick={() => setPickUpTime('')}
                        >
                          X
                        </span>
                        )}
                      </div>
                    )}

                    {foodDeliveryOption === 'DropOff' && (
                      <div className="venue-field">
                        <label>When will we be dropping off the food?&nbsp;</label>
                        <input
                          className='customDropdownTimeline'
                          type="time"
                          onChange={(e) => setDropOffTime(e.target.value)}
                          value={dropOffTime}
                        />
                        {dropOffTime && (<span
                          className="removeOptionTimeLine"
                          onClick={() => setDropOffTime('')}
                        >
                          X
                        </span>
                        )}
                      </div>
                    )}

                    {foodDeliveryOption !== 'Pick Up' && foodDeliveryOption !== 'DropOff' && (
                      <>
                        <div className="venue-field">
                          <label>When can the caterer access the venue?&nbsp;</label>
                          <input
                            className='customDropdownTimeline'
                            type="time"
                            onChange={(e) => setVenueAccessTime(e.target.value)}
                            value={venueAccessTime}
                          />
                          {venueAccessTime && (<span
                            className="removeOptionTimeLine"
                            onClick={() => setVenueAccessTime('')}
                          >
                            X
                          </span>
                          )}
                        </div>

                        <div className="venue-field">
                          <label>Food Service Start Time&nbsp;</label>
                          <input
                            className='customDropdownTimeline'
                            type="time"
                            onChange={(e) => setStartTime(e.target.value)}
                            value={startTime}
                          />
                          {startTime && (<span
                            className="removeOptionTimeLine"
                            onClick={() => setStartTime('')}
                          >
                            X
                          </span>
                          )}
                        </div>

                        <div className="venue-field">
                          <label>Food Service End Time&nbsp;</label>
                          <input
                            className='customDropdownTimeline'
                            type="time"
                            onChange={(e) => setEndTime(e.target.value)}
                            value={endTime}
                          />
                          {endTime && (<span
                            className="removeOptionTimeLine"
                            onClick={() => setEndTime('')}
                          >
                            X
                          </span>
                          )}
                        </div>
                      </>
                    )}

                    <div>
                      <textarea
                        rows='1'
                        placeholder="Notes"
                        onChange={(e) => {
                          setfoodTimeLineNotes(e.target.value);
                          adjustTextareaRows(e.target);
                        }}
                        value={foodTimeLineNotes}
                        className='otherInputBoxDrink'
                        style={{ overflow: "auto", resize: "none", maxWidth: "90%", lineHeight: "20px" }}
                      />
                    </div>
                  </div>
                </div>
              </div>


              {foodDeliveryOption !== 'Pick Up' && (

                <div className='accommodations-container'>
                  <div className='drinkCardGuest drinkCardGuestVenue'>
                    <h3>Venue</h3>
                    <div>
                      {kitchenOptions.map((option, index) => (
                        <div className="dropdownSectionKitchen" key={index}>
                          <select
                            className="customDropdownKitchen"
                            value={option.type}
                            onChange={(e) => handleKitchenChange(index, e.target.value)}
                          >
                            <option value="placeholder" disabled>Kitchen Options</option>
                            {kitchenDropdownOptions.map((kitchenOption, idx) => (
                              <option key={idx} value={kitchenOption}>{kitchenOption}</option>
                            ))}
                          </select>
                          {(option.type === 'No Kitchen' || option.type === 'Undetermined') && (
                            <div style={{ marginTop: '20px' }}>
                              <label>Does the venue<br></br>have potable water?</label>
                              <div>                          <input
                                type="radio"
                                name={`potableWater-${index}`}
                                value="Yes"
                                checked={potableWater === 'Yes'}
                                onChange={() => setPotableWater('Yes')}
                              /> Yes
                                <input
                                  type="radio"
                                  name={`potableWater-${index}`}
                                  value="No"
                                  checked={potableWater === 'No'}
                                  onChange={() => setPotableWater('No')}
                                /> No
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                    <br></br>
                    <div className=''>
                      <input
                        type="text"
                        placeholder="Street Address"
                        className='venueInput'
                        value={streetAddress}
                        onChange={handleStreetAddressChange}
                      />              </div>
                    <div className=''>
                      <input
                        type="text"
                        placeholder="Zip Code"
                        className='venueInput'
                        value={zipCode}
                        onChange={(e) => setZipCode(e.target.value)}
                      />              </div>
                    <div className=''>
                      <input
                        type="text"
                        placeholder="City"
                        className='venueInput'
                        value={city}
                        onChange={handleInputChange(setCity)}
                      />
                    </div>
                    <div className=''>
                      <label className="visually-hidden">State</label>
                      <select
                        className="otherInputBoxDrink"
                        value={selectedState}
                        onChange={handleStateChange}
                      >
                        <option value="">State</option>
                        {US_STATES.map((state, index) => (
                          <option key={index} value={state}>{state}</option>
                        ))}
                      </select>
                    </div>


                    <div className=''>
                      <textarea
                        rows='1'
                        placeholder="Venue Notes"
                        onChange={(e) => {
                          setVenueNotes(e.target.value); adjustTextareaRows(e.target);
                        }} value={venueNotes}
                        className='notesInputBoxDrink'
                        style={{ overflow: "auto", resize: "none", maxWidth: "90%", lineHeight: "20px" }}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className=' accommodations-container '>

                <div className="drinkCardGuest ">
                  <h3>Budget</h3>
                  <label className="input-group">
                    Min: $
                    <input
                      type="number"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      placeholder="0"
                      className="custom-input"
                      value={minBudget}
                      onFocus={handleFocusBudget}
                      onChange={e => handleMinBudgetChange(e.target.value)} />
                  </label>
                  <label className="input-group">
                    Max: $
                    <input
                      inputMode="numeric"
                      pattern="[0-9]*"
                      type="number"
                      placeholder="0"
                      className="custom-input"
                      value={maxBudget}
                      min={minBudget}
                      onChange={e => handleMaxBudgetChange(e.target.value)}
                      onBlur={handleMaxBudgetBlur} />
                  </label>
                </div>
              </div>

              <div className='accommodations-container'>
                <div className='drinkCardGuest'>
                  <h3>Contact</h3>
                  <div className='required-row'>
                    <span className='required-text'>* Required</span>
                  </div>
                  <div className=''>
                    <input
                      type="text"
                      placeholder="* First Name"
                      className='venueInput'
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                    {errorMessages.firstName && <p className='error-text'>{errorMessages.firstName}</p>}
                  </div>
                  <div className=''>
                    <input
                      type="text"
                      placeholder="* Last Name"
                      className='venueInput'
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                    {errorMessages.lastName && <p className='error-text'>{errorMessages.lastName}</p>}
                  </div>
                  <div className=''>
                    <input
                      type="text"
                      placeholder="* Phone Number"
                      className='venueInput'
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                    {errorMessages.phoneNumber && <p className='error-text'>{errorMessages.phoneNumber}</p>}
                  </div>
                  <div className=''>
                    <input
                      type="text"
                      placeholder="* Email Address"
                      className='venueInput'
                      value={customerEmailAddress}
                      onChange={(e) => setCustomerEmailAddress(e.target.value)}
                    />
                    {errorMessages.customerEmailAddress && <p className='error-text'>{errorMessages.customerEmailAddress}</p>}
                  </div>
                  <div className=''>
                    <textarea
                      rows='1'
                      placeholder="Additional Notes"
                      onChange={(e) => {
                        setAdditionalNotes(e.target.value); adjustTextareaRows(e.target);
                      }}
                      value={additionalNotes}
                      className='notesInputBoxDrink'
                      style={{ overflow: "auto", resize: "none", maxWidth: "90%", lineHeight: "20px" }}
                    />
                  </div>
                </div>
              </div>


              {!isEmbedded && (
                <div>
                  <button className="reserve-spot-button" onClick={handleSubmit}>
                    Show what will be sent to the caterer
                  </button>
                </div>
              )}
              {editableDocument && (
                <div className="raw-email-container">
                  <h3>Raw Email Data</h3>
                  <textarea
                    value={editableDocument}
                    onChange={(e) => setEditableDocument(e.target.value)}
                    className="raw-email-textarea"
                  />
                </div>
              )}
            </>
          </>
        )}
      </>
    </div>
  );
};
export default FoodForm;
//goodbye