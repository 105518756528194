import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { db } from './Firebase'; // Import Firebase setup
import { doc, setDoc, getDoc, updateDoc } from 'firebase/firestore';

const LoginButton = () => {
  const { loginWithRedirect, isAuthenticated, user } = useAuth0();

  useEffect(() => {
    const createOrUpdateUserInFirestore = async () => {
      if (!isAuthenticated || !user?.sub) return;

      try {
        const userId = user.sub; // Unique Auth0 user ID
        const userDocRef = doc(db, 'users', userId);
        const userDocSnap = await getDoc(userDocRef);

        // Default fields (including SES tracking)
        const defaultUserData = {
          emailAddress: user.email, // ✅ Ensures Firestore has the correct email field
          name: user.name || '',
          activeSubscription: false, // Default to no subscription
          createdAt: new Date().toISOString(),
          emailBounced: false, // ✅ SES bounce tracking
          emailComplained: false, // ✅ SES complaint tracking
          lastBounceTimestamp: null, // ✅ Tracks last bounce
          lastComplaintTimestamp: null, // ✅ Tracks last complaint
        };

        if (!userDocSnap.exists()) {
          // 🔥 Create a new user document
          console.log(`🔥 Creating a new Firestore document for user: ${userId}`);
          await setDoc(userDocRef, defaultUserData);
          console.log(`✅ Firestore user document created for: ${userId}`);
        } else {
          // 🛠 Update existing user if any SES fields are missing
          const existingUserData = userDocSnap.data();
          const fieldsToUpdate = {};

          Object.keys(defaultUserData).forEach((key) => {
            if (!(key in existingUserData)) {
              fieldsToUpdate[key] = defaultUserData[key];
            }
          });

          if (Object.keys(fieldsToUpdate).length > 0) {
            console.log(`🛠 Updating existing user with missing fields: ${userId}`);
            await updateDoc(userDocRef, fieldsToUpdate);
            console.log(`✅ Firestore user updated for: ${userId}`);
          } else {
            console.log(`🔹 User already has all required fields: ${userId}`);
          }
        }
      } catch (error) {
        console.error('🚨 Error creating/updating Firestore user document:', error);
      }
    };

    if (isAuthenticated) {
      createOrUpdateUserInFirestore();
    }
  }, [isAuthenticated, user]);

  return <button onClick={() => loginWithRedirect()}>Login</button>;
};

export default LoginButton;
