import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { db } from './Firebase'; // Firebase client SDK
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import LogoutButton from './LogoutButton';
import { authenticateWithFirebase } from './Pay'; // Import the shared function
import TermsOfServiceModal from "./TermsOfServiceModal";
import CancellationProcessModal from "./CancellationProcessModal";
import allGoogleFonts from './data/allGoogleFonts.json';

function generatePublicId() {
  return Math.random().toString(36).substr(2, 9);
}

function ClientPortal() {
  const { user, isAuthenticated, getAccessTokenSilently, logout } = useAuth0();
  const navigate = useNavigate();

  const [showTerms, setShowTerms] = useState(false);
  const [showCancellation, setShowCancellation] = useState(false);
  const [fontSearch, setFontSearch] = useState('');
  const [filteredFonts, setFilteredFonts] = useState([]);

  const [config, setConfig] = useState({
    primaryColor: '',
    secondaryColor: '',
    contrastColor: '',
    backgroundColor: '',
    fontPrimary: '',
    deliveryMethods: {
      delivered: false,
      droppedOff: false,
      pickedUp: false,
      deliveredPresentations: {
        plated: false,
        buffet: false,
        familyStyle: false,
        other: false,
      },
      deliveredEquipment: {
        Cutlery: { disposable: false, reusable: false },
        Plates: { disposable: false, reusable: false },
        Napkins: { disposable: false, reusable: false },
        Linen: { disposable: false, reusable: false },
      },
      droppedOffEquipment: {
        Cutlery: { disposable: false, reusable: false },
        Plates: { disposable: false, reusable: false },
        Napkins: { disposable: false, reusable: false },
        Linen: { disposable: false, reusable: false },
      },
      pickedUpEquipment: {
        Cutlery: { disposable: false, reusable: false },
        Plates: { disposable: false, reusable: false },
        Napkins: { disposable: false, reusable: false },
        Linen: { disposable: false, reusable: false },
      },
    },
    // Single default category
    customMenu: [
      {
        categoryName: 'New Category',
        subItems: [],
      },
    ],
    beverageOfferings: [
      {
        beverageName: 'New Beverage',
        types: [],
      },
    ],
  });
  const [activeSubscription, setActiveSubscription] = useState(false);
  const [embedCode, setEmbedCode] = useState('');

  // We'll define a variable for "Manage Billing"
  const customerPortalUrl = 'https://sandbox-customer-portal.paddle.com/cpl_01j8b0jkek534kde6wrcmv2gry';

  useEffect(() => {
    async function init() {
      try {
        if (!isAuthenticated || !user?.sub) {
          console.log('User not authenticated—redirecting to /');
          navigate('/');
          return;
        }

        const auth0Token = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: 'openid profile email',
        });
        await authenticateWithFirebase(auth0Token);

        const userDocRef = doc(db, 'users', user.sub);
        let userDocSnap = await getDoc(userDocRef);

        // If no doc, create with default subscription = false
        if (!userDocSnap.exists()) {
          console.log('Creating default Firestore document for new user:', user.sub);
          await setDoc(userDocRef, { activeSubscription: false });
          userDocSnap = await getDoc(userDocRef);
        }

        // If subscription not active, go to /pay
        if (!userDocSnap.data()?.activeSubscription) {
          console.log('No active subscription—redirecting to /pay');
          navigate('/pay');
          return;
        }

        console.log('User has an active subscription:', user.sub);
        setActiveSubscription(true);

        // Fetch or create config
        const configDocSnap = await getDoc(userDocRef);
        let userConfig;
        if (configDocSnap.exists()) {
          userConfig = configDocSnap.data();
        } else {
          userConfig = { ...config };
          await setDoc(userDocRef, userConfig);
        }

        // If no publicId, create
        if (!userConfig.publicId) {
          userConfig.publicId = generatePublicId();
          await setDoc(userDocRef, { publicId: userConfig.publicId }, { merge: true });
        }

        // If customMenu missing, set default
        if (!userConfig.customMenu) {
          userConfig.customMenu = [
            {
              categoryName: 'New Category',
              subItems: [],
            },
          ];
        }
        // If userConfig.beverageOfferings missing, initialize
        if (!userConfig.beverageOfferings) {
          userConfig.beverageOfferings = [
            {
              beverageName: 'New Beverage',
              types: [],
            },
          ];
        }


        setConfig(userConfig);

        // Build embed code
        const snippet = `<div id="food-form-container"></div>
<script src="https://collaborative-catering-21ab4e9a8bfb.herokuapp.com/embed-script/${userConfig.publicId}"></script>`;
        setEmbedCode(snippet);
      } catch (err) {
        console.error('Error loading client portal:', err);
        navigate('/');
      }
    }
    init();
    // eslint-disable-next-line
  }, [isAuthenticated, user, navigate, getAccessTokenSilently]);

  function handleLogout() {
    localStorage.removeItem('isLoggedIn');
    logout({
      returnTo: window.location.origin,
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
    });
  }

  // Toggling delivered/droppedOff/pickedUp
  function handleDeliveryMethodChange(method, checked) {
    setConfig(prev => {
      const copy = { ...prev };
      const methods = { ...copy.deliveryMethods };
      const numActive = ['delivered', 'droppedOff', 'pickedUp'].filter(m => methods[m]).length;
      if (!checked && methods[method]) {
        if (numActive === 1) {
          alert("At least one food delivery method must remain enabled.");
          return prev;
        }
      }
      methods[method] = checked;
      copy.deliveryMethods = methods;
      return copy;
    });
  }

  // Toggling presentations
  function handleDeliveredPresentationChange(key, isChecked) {
    setConfig(prev => {
      const copy = { ...prev };
      const pres = { ...copy.deliveryMethods.deliveredPresentations };
      pres[key] = isChecked;
      copy.deliveryMethods.deliveredPresentations = pres;
      return copy;
    });
  }

  // Toggling equipment
  function handleEquipmentChange(method, itemName, type, isChecked) {
    setConfig(prev => {
      const copy = { ...prev };
      const eqKey = method + 'Equipment';
      const eqObj = { ...copy.deliveryMethods[eqKey] };
      const itemObj = { ...eqObj[itemName] };
      itemObj[type] = isChecked;
      eqObj[itemName] = itemObj;
      copy.deliveryMethods[eqKey] = eqObj;
      return copy;
    });
  }

  // Standard 4 equipment items
  function renderEquipmentGrid(method) {
    const eqKey = method + 'Equipment';
    const eqState = config.deliveryMethods[eqKey] || {};
    const items = ['Cutlery', 'Plates', 'Napkins', 'Linen'];

    return (
      <div style={{ marginTop: '10px' }}>
        <h4 className="otherInputBoxDrinkCP" style={{ margin: '2px' }}>Equipment</h4>
        <div className="provided-by-grid">
          <div></div>
          <div>Disposable&nbsp;</div>
          <div>Reusable</div>

          {items.map(item => (
            <React.Fragment key={item}>
              <div>{item}</div>
              <div>
                <input
                  className="custom-checkbox"
                  type="checkbox"
                  checked={eqState[item]?.disposable || false}
                  onChange={e => handleEquipmentChange(method, item, 'disposable', e.target.checked)}
                />
              </div>
              <div>
                <input
                  className="custom-checkbox"
                  type="checkbox"
                  checked={eqState[item]?.reusable || false}
                  onChange={e => handleEquipmentChange(method, item, 'reusable', e.target.checked)}
                />
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    );
  }

  // For color & font changes
  function handlePrimaryColorChange(e) {
    const value = e.target.value;
    setConfig(prev => ({
      ...prev,
      primaryColor: value,
      secondaryColor: value
    }));
  }
  function handleChange(e) {
    const { name, value } = e.target;
    setConfig(prev => ({ ...prev, [name]: value }));
  }
  // ---------- BEVERAGE OFFERINGS LOGIC ----------

  // Add a new Beverage/Category
  function handleAddBeverage() {
    setConfig((prev) => {
      const copy = { ...prev };
      const bev = [...copy.beverageOfferings];
      bev.push({
        beverageName: '',
        types: [],
      });
      copy.beverageOfferings = bev;
      return copy;
    });
  }

  // Remove beverage if no types
  function handleRemoveBeverage(index) {
    setConfig((prev) => {
      const copy = { ...prev };
      const bev = [...copy.beverageOfferings];
      if (bev[index].types.length === 0) {
        bev.splice(index, 1);
      } else {
        alert('Cannot remove this beverage category. Remove its types first.');
      }
      copy.beverageOfferings = bev;
      return copy;
    });
  }

  // Rename beverage category
  function handleBeverageNameChange(index, newName) {
    setConfig((prev) => {
      const copy = { ...prev };
      const bev = [...copy.beverageOfferings];
      bev[index] = {
        ...bev[index],
        beverageName: newName,
      };
      copy.beverageOfferings = bev;
      return copy;
    });
  }

  // Add a type to a given beverage
  function handleAddType(index) {
    setConfig((prev) => {
      const copy = { ...prev };
      const bev = [...copy.beverageOfferings];
      bev[index] = {
        ...bev[index],
        types: [
          ...(bev[index].types || []),
          {
            title: '',
            subtitle: '',
            price: '',
            isEditing: true, // start in editing mode
          },
        ],
      };
      copy.beverageOfferings = bev;
      return copy;
    });
  }

  // Remove a type from a beverage
  function handleRemoveType(bevIndex, typeIndex) {
    setConfig((prev) => {
      const copy = { ...prev };
      const bev = [...copy.beverageOfferings];
      const b = { ...bev[bevIndex] };
      const newTypes = [...b.types];
      newTypes.splice(typeIndex, 1);
      b.types = newTypes;
      bev[bevIndex] = b;
      copy.beverageOfferings = bev;
      return copy;
    });
  }

  // Switch an existing type to editing mode
  function handleEditType(bevIndex, typeIndex) {
    setConfig((prev) => {
      const copy = { ...prev };
      const bev = [...copy.beverageOfferings];
      const b = { ...bev[bevIndex] };
      const newTypes = [...b.types];
      newTypes[typeIndex] = {
        ...newTypes[typeIndex],
        isEditing: true,
      };
      b.types = newTypes;
      bev[bevIndex] = b;
      copy.beverageOfferings = bev;
      return copy;
    });
  }

  // Each keystroke in editing mode
  function handleChangeTypeField(bevIndex, typeIndex, field, value) {
    setConfig((prev) => {
      const copy = { ...prev };
      const bev = [...copy.beverageOfferings];
      const b = { ...bev[bevIndex] };
      const newTypes = [...b.types];

      let newTitle = newTypes[typeIndex].title;
      let newSubtitle = newTypes[typeIndex].subtitle;
      let newPrice = newTypes[typeIndex].price;

      if (field === 'title') {
        newTitle = value;
      } else if (field === 'subtitle') {
        newSubtitle = value;
      } else if (field === 'price') {
        // auto prepend $
        const noDollar = value.replace(/\$/g, '');
        newPrice = '$' + noDollar;
      }

      newTypes[typeIndex] = {
        ...newTypes[typeIndex],
        title: newTitle,
        subtitle: newSubtitle,
        price: newPrice,
      };

      b.types = newTypes;
      bev[bevIndex] = b;
      copy.beverageOfferings = bev;
      return copy;
    });
  }

  // Done editing a type
  function handleDoneEditingType(bevIndex, typeIndex) {
    setConfig((prev) => {
      const copy = { ...prev };
      const bev = [...copy.beverageOfferings];
      const b = { ...bev[bevIndex] };
      const newTypes = [...b.types];

      const t = newTypes[typeIndex];
      if (!t.title.trim()) {
        alert('Type is required');
        return prev; // no changes
      }

      newTypes[typeIndex] = {
        ...t,
        title: t.title.trim(),
        subtitle: t.subtitle.trim(),
        price: t.price.trim(),
        isEditing: false,
      };

      b.types = newTypes;
      bev[bevIndex] = b;
      copy.beverageOfferings = bev;
      return copy;
    });
  }

  // Render each type row
  function renderType(bevIndex, typeObj, typeIndex) {
    if (typeObj.isEditing) {
      // editing
      return (
        <div
          key={typeIndex}
          className="parentContainer drinkCardGuest"
          style={{
            marginTop: '5px',
            display: 'flex',
            alignItems: 'flex-start',
            gap: '8px',
            position: 'relative',
          }}
        >
          <span
            className="deleteCircle"
            onClick={() => handleRemoveType(bevIndex, typeIndex)}
          >
            X
          </span>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <input
              className="otherInputBoxDrinkCP"
              type="text"
              placeholder="Type*"
              value={typeObj.title}
              onChange={(e) => handleChangeTypeField(bevIndex, typeIndex, 'title', e.target.value)}
            />
            <input
              className="otherInputBoxDrinkCP"
              type="text"
              placeholder="Subtitle"
              value={typeObj.subtitle}
              onChange={(e) => handleChangeTypeField(bevIndex, typeIndex, 'subtitle', e.target.value)}
            />
            <input
              className="otherInputBoxDrinkCP"
              type="text"
              placeholder="Price"
              value={typeObj.price}
              onChange={(e) => handleChangeTypeField(bevIndex, typeIndex, 'price', e.target.value)}
            />
            <button
              type="button"
              style={{ fontSize: '0.7em', width: '60px', marginTop: '2px' }}
              onClick={() => handleDoneEditingType(bevIndex, typeIndex)}
            >
              Done
            </button>
          </div>
        </div>
      );
    } else {
      // read-only
      return (
        <div
          key={typeIndex}
          className="parentContainer drinkCardGuest"
          style={{
            marginTop: '5px',
            display: 'flex',
            alignItems: 'flex-start',
            gap: '8px',
            position: 'relative',
          }}
        >
          <span
            className="deleteCircle"
            onClick={() => handleRemoveType(bevIndex, typeIndex)}
          >
            X
          </span>
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => handleEditType(bevIndex, typeIndex)}
          >
            <div>{typeObj.title} {typeObj.price}</div>
            {typeObj.subtitle && <div>{typeObj.subtitle}</div>}
          </div>
        </div>
      );
    }
  }

  // ---------- FOOD SELECTION LOGIC ----------

  // Add a blank new category
  function handleAddCategory() {
    setConfig(prev => {
      const copy = { ...prev };
      const cats = [...copy.customMenu];
      cats.push({
        categoryName: '',
        subItems: [],
      });
      copy.customMenu = cats;
      return copy;
    });
  }

  // Remove category only if it has 0 items
  function handleRemoveCategory(catIndex) {
    setConfig(prev => {
      const copy = { ...prev };
      const cats = [...copy.customMenu];
      const c = cats[catIndex];
      // We only remove if c.subItems.length===0
      if (c.subItems.length === 0) {
        cats.splice(catIndex, 1);
      } else {
        alert('Cannot remove this category. Remove items first.');
      }
      copy.customMenu = cats;
      return copy;
    });
  }

  // Category name changes
  function handleCategoryNameChange(catIndex, newName) {
    setConfig(prev => {
      const copy = { ...prev };
      const cats = [...copy.customMenu];
      cats[catIndex] = {
        ...cats[catIndex],
        categoryName: newName,
      };
      copy.customMenu = cats;
      return copy;
    });
  }

  // Add a subitem
  function handleAddItem(catIndex) {
    setConfig(prev => {
      const copy = { ...prev };
      const cats = [...copy.customMenu];
      const cat = { ...cats[catIndex] };
      cat.subItems = [...(cat.subItems || [])];
      cat.subItems.push({
        title: '',
        subtitle: '',
        price: '',
        isEditing: true,
      });
      cats[catIndex] = cat;
      copy.customMenu = cats;
      return copy;
    });
  }

  // Remove sub item
  function handleRemoveSubItem(catIndex, subIndex) {
    setConfig(prev => {
      const copy = { ...prev };
      const cats = [...copy.customMenu];
      const cat = { ...cats[catIndex] };
      const items = [...cat.subItems];
      items.splice(subIndex, 1);
      cat.subItems = items;
      cats[catIndex] = cat;
      copy.customMenu = cats;
      return copy;
    });
  }

  // Switch an item to editing
  function handleEditItem(catIndex, subIndex) {
    setConfig(prev => {
      const copy = { ...prev };
      const cats = [...copy.customMenu];
      const cat = { ...cats[catIndex] };
      const items = [...cat.subItems];
      items[subIndex] = {
        ...items[subIndex],
        isEditing: true,
      };
      cat.subItems = items;
      cats[catIndex] = cat;
      copy.customMenu = cats;
      return copy;
    });
  }

  // Each keystroke in editing mode
  function handleChangeItemField(catIndex, subIndex, field, value) {
    setConfig(prev => {
      const copy = { ...prev };
      const cats = [...copy.customMenu];
      const cat = { ...cats[catIndex] };
      const items = [...cat.subItems];

      let newTitle = items[subIndex].title;
      let newSubtitle = items[subIndex].subtitle;
      let newPrice = items[subIndex].price;

      if (field === 'title') {
        newTitle = value;
      } else if (field === 'subtitle') {
        newSubtitle = value;
      } else if (field === 'price') {
        // auto prepend $
        const valNoDollar = value.replace(/\$/g, '');
        newPrice = '$' + valNoDollar;
      }

      items[subIndex] = {
        ...items[subIndex],
        title: newTitle,
        subtitle: newSubtitle,
        price: newPrice,
      };

      cat.subItems = items;
      cats[catIndex] = cat;
      copy.customMenu = cats;
      return copy;
    });
  }

  // Done editing item
  function handleDoneEditing(catIndex, subIndex) {
    setConfig(prev => {
      const copy = { ...prev };
      const cats = [...copy.customMenu];
      const cat = { ...cats[catIndex] };
      const items = [...cat.subItems];

      const item = items[subIndex];
      if (!item.title.trim()) {
        alert('Title is required');
        return prev;
      }

      items[subIndex] = {
        ...item,
        title: item.title.trim(),
        subtitle: item.subtitle.trim(),
        price: item.price.trim(),
        isEditing: false,
      };

      cat.subItems = items;
      cats[catIndex] = cat;
      copy.customMenu = cats;
      return copy;
    });
  }

  // Render each sub item
  function renderSubItem(catIndex, sub, subIndex) {
    if (sub.isEditing) {
      return (
        <div
          key={subIndex}
          className="parentContainer drinkCardGuest"
          style={{
            marginTop: '5px',
            display: 'flex',
            alignItems: 'flex-start',
            gap: '8px',
            position: 'relative'
          }}
        >
          {/* X in upper-right for item */}
          <span
            className="deleteCircle"
            onClick={() => handleRemoveSubItem(catIndex, subIndex)}
          >
            X
          </span>

          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <input
              className="otherInputBoxDrinkCP"
              type="text"
              placeholder="Item*"
              value={sub.title}
              onChange={e => handleChangeItemField(catIndex, subIndex, 'title', e.target.value)}
              style={{ width: '200px' }}
            />
            <input
              className="otherInputBoxDrinkCP"
              type="text"
              placeholder="Price"
              value={sub.price}
              onChange={e => handleChangeItemField(catIndex, subIndex, 'price', e.target.value)}
              style={{ width: '200px' }}
            />
            <input
              className="otherInputBoxDrinkCP"
              type="text"
              placeholder="Subtitle"
              value={sub.subtitle}
              onChange={e => handleChangeItemField(catIndex, subIndex, 'subtitle', e.target.value)}
              style={{ width: '200px' }}
            />
            <button
              type="button"
              style={{ fontSize: '0.7em', width: '60px', marginTop: '2px' }}
              onClick={() => handleDoneEditing(catIndex, subIndex)}
            >
              Done
            </button>
          </div>
        </div>
      );
    } else {
      // read-only
      return (
        <div
          key={subIndex}
          className="parentContainer drinkCardGuest"
          style={{
            marginTop: '5px',
            display: 'flex',
            alignItems: 'flex-start',
            gap: '8px',
            position: 'relative'
          }}
        >
          <span
            className="deleteCircle"
            onClick={() => handleRemoveSubItem(catIndex, subIndex)}
          >
            X
          </span>

          <div style={{ cursor: 'pointer' }} onClick={() => handleEditItem(catIndex, subIndex)}>
            <div>{sub.title} {sub.price}</div>
            {sub.subtitle && <div>{sub.subtitle}</div>}
          </div>
        </div>
      );
    }
  }

  // If all categories have a name, show Add Category
  const canShowAddCategory = config.customMenu.every(
    cat => cat.categoryName.trim() !== ''
  );
  function handleFontSearchChange(inputValue) {
    setFontSearch(inputValue);
    
    // If empty, no results
    if (!inputValue.trim()) {
      setFilteredFonts([]);
      return;
    }
  
    const lowered = inputValue.toLowerCase();
  
    // Filter the entire list of fonts from allGoogleFonts
    // (the relevant array is allGoogleFonts.items, each with {family, ...})
    const results = allGoogleFonts.items
      .map((f) => f.family) // just the family name string
      .filter((fam) => fam.toLowerCase().includes(lowered))
      .slice(0, 50); // limit top 50 matches
  
    setFilteredFonts(results);
  }
  
  function handleFontPick(fontFamily) {
    // set config.fontPrimary to e.g. "Lato, sans-serif"
    setConfig((prev) => ({
      ...prev,
      fontPrimary: `"${fontFamily}", sans-serif`,
    }));
  
    // Clear the search
    setFontSearch('');
    setFilteredFonts([]);
  }
  

  // Save entire config
  async function handleSubmit(e) {
    e.preventDefault();
    try {
      const userDocRef = doc(db, 'users', user.sub);
      await setDoc(userDocRef, config, { merge: true });
      alert('Configuration updated successfully.');
    } catch (error) {
      console.error('Error updating config:', error);
      alert('Failed to update. Please try again.');
    }
  }

  if (!activeSubscription) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ padding: '20px', textAlign: 'left' }}>
      <LogoutButton onClick={handleLogout} />

      <h1>Form Configuration</h1>
      <form onSubmit={handleSubmit}>

        {/* ---------- FOOD DELIVERY METHODS ---------- */}
        <div className="drinkCardGuest">
          <h2 style={{ margin: '2px' }}>Food Delivery Methods</h2>

          {/* Delivered & Served (own row) */}
          <div className="drinkCardGuest" style={{ marginBottom: '20px' }}>
            <h3 style={{ margin: '2px' }}>
              <input
                type="checkbox"
                checked={config.deliveryMethods.delivered}
                onChange={e => handleDeliveryMethodChange('delivered', e.target.checked)}
              />
              Delivered &amp; Served
            </h3>
            {config.deliveryMethods.delivered && (
              <div style={{ marginLeft: '10px' }}>
                <h4 className="otherInputBoxDrinkCP" style={{ margin: '2px' }}>Presentations</h4>
                <label>
                  <input
                    type="checkbox"
                    checked={config.deliveryMethods.deliveredPresentations.plated}
                    onChange={e => handleDeliveredPresentationChange('plated', e.target.checked)}
                  />
                  Plated
                </label>
                <br />
                <label>
                  <input
                    type="checkbox"
                    checked={config.deliveryMethods.deliveredPresentations.buffet}
                    onChange={e => handleDeliveredPresentationChange('buffet', e.target.checked)}
                  />
                  Buffet
                </label>
                <br />
                <label>
                  <input
                    type="checkbox"
                    checked={config.deliveryMethods.deliveredPresentations.familyStyle}
                    onChange={e => handleDeliveredPresentationChange('familyStyle', e.target.checked)}
                  />
                  Family Style
                </label>
                <br />
                <label>
                  <input
                    type="checkbox"
                    checked={config.deliveryMethods.deliveredPresentations.other}
                    onChange={e => handleDeliveredPresentationChange('other', e.target.checked)}
                  />
                  Other
                </label>
                <br />
                {renderEquipmentGrid('delivered')}
                <br />
              </div>
            )}
          </div>

          {/* Dropped Off (own row) */}
          <div className="drinkCardGuest" style={{ marginBottom: '20px' }}>
            <h3 style={{ margin: '2px' }}>
              <input
                type="checkbox"
                checked={config.deliveryMethods.droppedOff}
                onChange={e => handleDeliveryMethodChange('droppedOff', e.target.checked)}
              />
              Dropped Off
            </h3>
            {config.deliveryMethods.droppedOff && (
              <>
                {renderEquipmentGrid('droppedOff')}
                <br />
              </>
            )}
          </div>

          {/* Picked Up (own row) */}
          <div className="drinkCardGuest" style={{ marginBottom: '20px' }}>
            <h3 style={{ margin: '2px' }}>
              <input
                type="checkbox"
                checked={config.deliveryMethods.pickedUp}
                onChange={e => handleDeliveryMethodChange('pickedUp', e.target.checked)}
              />
              Picked Up
            </h3>
            {config.deliveryMethods.pickedUp && (
              <>
                {renderEquipmentGrid('pickedUp')}
                <br />
              </>
            )}
          </div>
        </div>

        {/* ---------- FOOD SELECTION ---------- */}
        <div className="drinkCardGuest" style={{ marginBottom: '20px' }}>
          <h2 style={{ margin: '2px' }}>Food Selection</h2>
          <p>* Required</p>

          {config.customMenu.map((cat, catIndex) => (
            // Category container
            <div
              key={catIndex}
              className="parentContainer drinkCardGuest"
              style={{
                padding: '8px',
                marginBottom: '15px',
                position: 'relative'
              }}
            >
              {/* Category X in upper-left, only remove if 0 items */}
              <span
                className="deleteCircleCategory"
                onClick={() => handleRemoveCategory(catIndex)}
              >
                X
              </span>

              <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '10px', gap: '8px' }}>
                <input
                  className="otherInputBoxDrinkCP"
                  type="text"
                  placeholder="Category ex. Appetizers"
                  value={cat.categoryName}
                  onChange={e => handleCategoryNameChange(catIndex, e.target.value)}
                  style={{
                    flexGrow: 1,
                    outline: 'none',
                    padding: '4px'
                  }}
                />
              </div>

              {/* SubItems */}
              {cat.subItems && cat.subItems.length > 0 && (
                <div style={{ marginLeft: '8px' }}>
                  {cat.subItems.map((sub, subIndex) => renderSubItem(catIndex, sub, subIndex))}
                </div>
              )}

              <button
                type="button"
                style={{ fontSize: '0.8em', marginTop: '8px' }}
                onClick={() => handleAddItem(catIndex)}
              >
                Add Item
              </button>
            </div>
          ))}

          {/* Only show "Add Category" if each category has a non-empty name */}
          {canShowAddCategory && (
            <button type="button" onClick={handleAddCategory} style={{ fontSize: '0.8em' }}>
              Add Category
            </button>
          )}
        </div>
        {/* ---------- BEVERAGE OFFERINGS ---------- */}
        <div className="drinkCardGuest" style={{ marginBottom: '20px' }}>
          <h2 style={{ margin: '2px' }}>Beverage Offerings</h2>
          <p>* indicates required</p>

          {config.beverageOfferings.map((bev, bevIndex) => (
            <div
              key={bevIndex}
              className="parentContainer drinkCardGuest"
              style={{
                position: 'relative',
                padding: '8px',
                marginBottom: '15px',
              }}
            >
              {/* X in top-left for removing beverage if empty */}
              <span
                className="deleteCircleCategory"
                onClick={() => handleRemoveBeverage(bevIndex)}
              >
                X
              </span>

              {/* Input for "Beverage/Category*" */}
              <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '10px', gap: '8px' }}>
                <input
                  className="otherInputBoxDrinkCP"
                  type="text"
                  placeholder="Beverage/Category*"
                  value={bev.beverageName}
                  onChange={(e) => handleBeverageNameChange(bevIndex, e.target.value)}
                  style={{
                    flexGrow: 1,
                    outline: 'none',
                    padding: '4px',
                  }}
                />
              </div>

              {/* Render existing "types" for this beverage */}
              {bev.types && bev.types.length > 0 && (
                <div style={{ marginLeft: '8px' }}>
                  {bev.types.map((typeObj, typeIndex) =>
                    renderType(bevIndex, typeObj, typeIndex)
                  )}
                </div>
              )}

              {/* Add Type button */}
              <button
                type="button"
                style={{ fontSize: '0.8em', marginTop: '8px' }}
                onClick={() => handleAddType(bevIndex)}
              >
                Add Type
              </button>
            </div>
          ))}

          {/* Add Another Beverage/Category button 
      Show it only if all beverageName are non-empty 
  */}
          {config.beverageOfferings.every(bev => bev.beverageName.trim() !== '') && (
            <button type="button" onClick={handleAddBeverage} style={{ fontSize: '0.8em' }}>
              Add Another Beverage/Category
            </button>
          )}
        </div>

        {/* ---------- FONTS & COLORS ---------- */}
        <div className="drinkCardGuest" style={{ marginBottom: '2px' }}>
          <h3 style={{ margin: '2px' }}>Fonts &amp; Colors</h3>

          <div className="formRow" style={{ marginBottom: '2px' }}>
            <label>Header Color:</label>
            <input
              className="otherInputBoxDrinkCP"
              type="text"
              name="primaryColor"
              value={config.primaryColor}
              onChange={handlePrimaryColorChange}
              placeholder="#Hex Code"
            />
          </div>

          <div className="formRow" style={{ marginBottom: '2px' }}>
            <label>Underline &amp; Button Color:</label>
            <input
              className="otherInputBoxDrinkCP"
              type="text"
              name="contrastColor"
              value={config.contrastColor}
              onChange={handleChange}
              placeholder="#Hex Code"
            />
          </div>

          <div className="formRow" style={{ marginBottom: '2px' }}>
            <label>Background Color:</label>
            <input
              className="otherInputBoxDrinkCP"
              type="text"
              name="backgroundColor"
              value={config.backgroundColor}
              onChange={handleChange}
              placeholder="#Hex Code"
            />
          </div>

          {/* The special row for “Font:” */}
          <div className="formRow" style={{ marginBottom: '2px', position: 'relative' }}>
            <label style={{ marginRight: '5px' }}>Font (type to search):</label>

            {/* The text input for searching fonts */}
            <input
              type="text"
              className="otherInputBoxDrinkCP"
              value={fontSearch}
              onChange={(e) => handleFontSearchChange(e.target.value)}
              placeholder="e.g. 'Lato'"
              style={{ width: '200px' }}
            />

            {/* 
    If we have filtered results, show them in a little dropdown 
    You can style or position it as you like
  */}
            {filteredFonts.length > 0 && (
              <ul
                style={{
                  position: 'absolute',
                  top: '35px',   // below the input
                  left: '110px', // adjust to align under your text
                  background: '#fff',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  padding: '5px',
                  maxHeight: '150px',
                  overflowY: 'auto',
                  width: '200px',
                  listStyle: 'none',
                  margin: 0,
                }}
              >
                {filteredFonts.map((family) => (
                  <li
                    key={family}
                    style={{ cursor: 'pointer', margin: '4px 0' }}
                    onClick={() => handleFontPick(family)}
                  >
                    {family}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>

        <button type="submit" style={{ margin: '20px 0' }}>
          Save Configuration
        </button>
      </form>

      <div style={{ margin: '20px 0' }}>
        <p>Catering quote sends to: <strong>info@collaborativecatering.com</strong></p>
      </div>

      {/* EMBED CODE */}
      {embedCode && (
        <div style={{ marginTop: '20px' }}>
          <h3>Embed This Form on Your Website</h3>
          <p>Copy and paste the following code snippet into your website:</p>
          <div
            style={{
              backgroundColor: '#f6f8fa',
              padding: '15px',
              borderRadius: '5px',
              border: '1px solid #ddd',
              fontFamily: 'monospace',
              fontSize: '14px',
              overflowX: 'auto',
              whiteSpace: 'pre',
            }}
          >
            <code>{embedCode}</code>
          </div>
          <div style={{ marginTop: '10px', textAlign: 'center' }}>
            <button
              onClick={() => {
                navigator.clipboard.writeText(embedCode);
                alert('Embed code copied to clipboard!');
              }}
              style={{
                backgroundColor: '#007BFF',
                color: '#FFF',
                border: 'none',
                borderRadius: '5px',
                padding: '8px 16px',
                cursor: 'pointer',
              }}
            >
              Copy
            </button>
          </div>
        </div>
      )}

      <button onClick={() => setShowTerms(true)}>View Terms &amp; Conditions</button>
      {showTerms && <TermsOfServiceModal onClose={() => setShowTerms(false)} />}

      <button onClick={() => setShowCancellation(true)}>View Cancellation Process</button>
      {showCancellation && <CancellationProcessModal onClose={() => setShowCancellation(false)} />}

      <button
        onClick={() => { window.location.href = config.customerPortalUrl || customerPortalUrl; }}
      >
        Manage Billing
      </button>
    </div>
  );
}

export default ClientPortal;
